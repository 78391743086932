<template>
  <v-app>
    <v-navigation-drawer v-model="openedMenu" app v-if="isUserLoggedIn">
      <!-- Imagem do logo -->
      <v-row class="text-center">
        <v-col align="center">
          <v-img
            v-if="$vuetify.theme.dark"
            class="logo"
            src="/logo-mono-light.svg"
            :aspect-ratio="2"
            width="200"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-img
            v-else
            class="logo"
            src="/favicon.svg"
            :aspect-ratio="2"
            width="200"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>

      <!-- Menu Lateral -->
      <v-list-item link to="/profile">
        <!-- Avatar -->
        <k-avatar
          size="36"
          :picture="loggedUser.avatar && loggedUser.avatar.url"
          :name="loggedUser && loggedUser.profile && loggedUser.profile.name"
          class="mr-2"
        ></k-avatar>

        <!-- Dados do Usuário -->
        <v-list-item-content class="pa-0" id="menu-header-logged-user">
          <v-list-item-title class="text-truncate">{{
            loggedUser && loggedUser.profile && loggedUser.profile.name
          }}</v-list-item-title>
          <v-list-item-subtitle class="text-truncate">{{
            loggedUser && loggedUser.email
          }}</v-list-item-subtitle>
        </v-list-item-content>

        <!-- Botão de Sair -->
        <v-list-item-action>
          <v-btn
            icon
            ripple
            title="Sair do Sistema"
            @click.stop.prevent="onLogoutButtonClick"
          >
            <v-icon color="error">mdi-logout</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <!-- Itens do Menu -->
      <v-list dense>
        <!-- Menu automático de acordo com as rotas -->
        <k-menu></k-menu>
      </v-list>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar app elevate-on-scroll>
      <!-- Se não existir a configuração do botão de voltar, mostre o botão do menu -->
      <v-app-bar-nav-icon
        @click.stop="openedMenu = !openedMenu"
        v-if="isUserLoggedIn && !showBackButton.show"
      />

      <!-- Se existir a configuração do botão de voltar, mostre-o -->
      <v-btn
        style="margin-left: -12px"
        icon
        exact
        v-if="showBackButton.show"
        @click="showBackButton.onClick"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ title || $route.meta.title }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- Notificações -->
      <!-- <NotificationButton v-if="isUserLoggedIn" /> -->

      <!-- Chip que mostra o ambiente -->
      <environment-chip></environment-chip>

      <!-- Botão de troca do tema -->
      <toggle-theme></toggle-theme>
    </v-app-bar>

    <!-- Conteúdo -->
    <v-main class="" :class="routeClass">
      <!-- Conteúdo -->
      <v-container fill-height class="align-start pa-0" fluid>
        <v-slide-x-transition mode="out-in">
          <router-view :key="$route.path"></router-view>
        </v-slide-x-transition>
      </v-container>
    </v-main>

    <!-- 
    Rodapé. 
    O valor precisa ser especificamente false. 
    Qualquer outro valor o rodapé é exibido 
    -->
    <v-footer height="46" v-if="$route.meta.footer !== false">
      <v-row>
        <v-col class="pa-0 text-center caption" align-items="center"
          >{{ copyRightText.name }} &copy; {{ copyRightText.year }} -
          <router-link
            to="/privacidade"
            :class="{ 'white--text': $vuetify.theme.dark }"
            >Política de Privacidade</router-link
          >
        </v-col>
      </v-row>
    </v-footer>

    <!-- Mensagens de aviso -->
    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.visible = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Loading em tela cheia -->
    <v-dialog
      ref="loading_dialog"
      v-model="loadingSettings.visibility"
      :hide-overlay="false"
      persistent
      width="300"
    >
      <v-card color="primary">
        <v-card-text class="pt-2 neutral--text">
          {{ loadingSettings.text }}
          <v-progress-linear
            indeterminate
            color="neutral"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Bus from "@/plugins/EventsBus";
import KMenu from "@/components/KMenu";
import KAvatar from "@/components/KAvatar.vue";
import EnvironmentChip from "./components/EnvironmentChip/index.vue";
import ToggleTheme from "./components/ToggleTheme/index.vue";
// import NotificationButton from "@/views/Home/NotificationButton";

export default {
  name: "App",

  components: {
    KMenu,
    KAvatar,
    // NotificationButton
    EnvironmentChip,
    ToggleTheme
  },

  data() {
    return {
      //Configurções da snackbar
      snackbar: {
        visible: false,
        timeout: 2000,
        text: "",
        color: ""
      },

      // Configurações do loading em tela cheia
      loadingSettings: {
        visibility: false,
        text: ""
      },

      //Opções do menu
      // Estado inicial: se for mobile, comece fechado. Senão, aberto.
      openedMenu: this.$vuetify.breakpoint.smAndDown ? false : true,

      // Logo carregado
      logoLoaded: false,

      title: null
    };
  },

  computed: {
    /**
     * Class do content de acorcdo com a rota atual
     */
    routeClass() {
      // const darkRoutes = ["/"];
      // if (darkRoutes.includes(this.$route.path)) {
      //   return "grey lighten-4";
      // }

      return "";
    },

    /**
     * Retorna true se o usuário estiver logado
     */
    isUserLoggedIn() {
      // return this.$store.getters['auth/isLoggedIn'];
      return this.$store.getters["auth/isLoggedIn"];
    },

    /**
     * Retorna a foto do Usuário
     */
    loggedUser() {
      if (this.isUserLoggedIn) {
        return { ...this.$store.getters["auth/user"] };
        // return Api.getCurrentUser();
      } else {
        return null;
      }
    },

    /**
     * Mostrar botão de voltar?
     * Se o botão de voltar tiver somente true, volta à página anterior
     * Se tiver preenchido mas não for true, ou seja, é uma string com
     * uma url, navega para esta url.
     * Se não tiver nada, apenas retorne false.
     */
    showBackButton() {
      //
      const back = this.$route.meta.backButton;
      //
      const action = {
        show: true
      };

      // Volta para a página anterior
      if (back === true) action.onClick = () => this.$router.back();
      else if (typeof back === "function") {
        action.onClick = () => this.$router.push(back(this.$route));
      } else if (back) action.onClick = () => this.$router.push(back);
      else return { show: false };

      return action;
    },

    /**
     * Texto do copyright do rodapé
     */
    copyRightText() {
      const settings = this.$store.getters["settings/values"];
      return {
        name: settings.COMPANY_NAME,
        year: new Date().getFullYear()
      };
    }
  },

  // filters: {
  //   truncate(value, max) {
  //     max = max || 30;
  //     return value.toString().substr(0, max) + "…";
  //   }
  // },

  //Métodos
  methods: {
    /**
     * Ao clicar no botão de logout
     */
    onLogoutButtonClick() {
      this.$api.logout();
    },

    /**
     * Ao carregar o logo, marque como carregado para inclusão
     * da cor de fundo
     */
    // onLogoLoaded() {
    //   setTimeout(() => {
    //     this.logoLoaded = true;
    //   }, 200);
    // },

    /*
     * Exibe uma mensagem de sucesso ou de erro
     */
    message(text, color, timeout = 3000) {
      var open = () => {
        this.snackbar.text = text;
        this.snackbar.color = color;
        this.snackbar.visible = true;
        this.snackbar.timeout = timeout;
      };

      //As especificações do Material Design exigem que uma mensagem recebida
      //feche a anterior antes de ser exibida. Verifico se já existe alguma aberta
      //e fecho-a antes de abrir a próxima.
      if (this.snackbar.visible === false) {
        open();
      } else {
        this.snackbar.visible = false;
        //Aguarda 250 milissegundos antes de abrir a nova
        setTimeout(open, 250);
      }
    },

    loading(visibility, text) {
      this.loadingSettings = {
        visibility,
        text
      };
    },

    /********************
     * MANIPULADORES DOS EVENTOS RECEBIDOS DOS COMPONENTES FILHOS
     ********************/

    /**
     * Ao receber uma mensagem vinda de algum componente filho
     */
    onMessageReceived(type, text) {
      this.message(text, type);
    }
  },

  //
  created() {
    // Adiciona o escutador de eventos do loading
    Bus.$on("loading", (visibility, text) => this.loading(visibility, text));

    // Adiciona o escutador de eventos das mensagens
    Bus.$on("message", (color, text, timeout = 3000) =>
      this.message(text, color, timeout)
    );

    Bus.$on("app:title", title => (this.title = title));

    // Carrega as configurações
    this.$store.dispatch("settings/all");
  }
};
</script>
<style>
.gradient {
  background-image: linear-gradient(#f5f5f5, white);
}
/* .v-image.logo > .v-image__image { */
/* Aumenta um pouco para remover bordas fantasmas ao redor */
/* background-size: 102%; */
/* } */
</style>
