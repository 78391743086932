import Api from "@/plugins/Api";

const baseURL = 'messages';

export default {
  // Define que este será acessado via namespace
  namespaced: true,

  // Armazena o estado
  state: {
    pagination: {},
    items: [],
  },

  // Responsável por alterar o estado diretamente
  mutations: {
    // Adiciona um item recebido via socket
    addItem(stateObject, data) {
      console.log('addItem', data)
      stateObject.items.unshift(data)
      stateObject.pagination.total = stateObject.pagination.total + 1
      stateObject.pagination.unread = stateObject.pagination.unread + 1
    },

    updateItems(stateObject, { total, perPage, lastPage, page, data, unread }) {
      const hasNext = page !== lastPage
      stateObject.pagination = { total, perPage, lastPage, page, unread, hasNext };
      // console.log(data)
      if (page === 1) stateObject.items = data;
      else stateObject.items.push(...data);

    },
    readItem(stateObject, data) {
      stateObject.items = stateObject.items.map(item => item.id === data.id ? data : item)
      stateObject.pagination.unread = stateObject.pagination.unread - 1
    },
    markMessageAsUnread(stateObject, data) {
      stateObject.items = stateObject.items.map(item => item.id === data.id ? { ...item, read_at: null } : item)
    },
    removeItem(stateObject, data) {
      stateObject.items = data
    },
    clearState(stateObject) {
      stateObject.pagination = {}
      stateObject.items = []
    }
  },

  // Executa upload, consultas a bancos de ddos, apis, etc.
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }) {
      if (state.items.length === 0) {

        await dispatch('refresh')
      }
      return state.items;
    },

    /**
     * Força a atualização dos dados
     */
    // async refresh({ commit }, { page = 1, limit = 20, q = null, type = null }) {
    async refresh({ commit }, queryString) {

      try {
        let { data: { data } } = await Api.get(`/${baseURL}`, queryString);
        // console.log(data)
        commit("updateItems", data);
      } catch (error) {
        throw error;
      }
    },

    async nextPage({ commit, state }) {

      try {
        let page = state.pagination.page + 1
        console.log('curr', page)
        let { data: { data } } = await Api.get(`/${baseURL}`, { page });
        console.log('Resultado: ', data)
        commit("updateItems", data);
      } catch (error) {
        console.log(error)
        throw error;
      }
    },

    newMessage({ commit }, payload) {
      try {
        console.log('nova mensagem', payload)
        commit("addItem", payload);
      } catch (error) {
        throw error;
      }
    },



    /**
     * Lê uma mensagem
     */
    async read({ state, commit }, messageId) {
      try {
        // console.log('read', messageId);
        // Pego os dados que estão no store
        const message = state.items.find(item => {
          // console.log('dentro do find', item.id, messageId);

          return item.id == messageId
        });
        // console.log('mensagem do store', messageId, message);


        if (!message || message.read_at === null) {
          const { data: { data } } = await Api.get(`/${baseURL}/${messageId}`)
          // console.log('mensagem lida agora');

          commit('readItem', data)
          return data;
        } else {
          // console.log('mensagem já lida');
          return message
        }


        // Cata a mensagem na lista e atualiza seus dados

      } catch (error) {
        console.log('err', error);

        throw error;
      }
    },


    /**
     * Marca todas como lidas
     */
    async markAllAsRead({ commit }) {
      try {
        const { data: { data } } = await Api.post(`/${baseURL}/mark-all-as-read`);
      } catch (error) {
        throw error;
      }
    },

    /**
     * Marca todas como lidas
     */
    async markMessageAsUnread({ commit }, message) {
      try {
        const { data: { data } } = await Api.post(`/${baseURL}/mark-message-as-unread/${message.id}`);

        console.log(data);


        commit('markMessageAsUnread', message)

      } catch (error) {
        throw error;
      }
    },


    /**
     * Deleta todas as mensagens
     */
    async deleteAll({ commit }) {
      try {
        const { data: { data } } = await Api.post(`/${baseURL}/delete-all`);
      } catch (error) {
        throw error;
      }
    },



    /**
     * Remove o cadastro
     */
    async remove({ commit, state }, formData) {
      try {
        // Requisição para deletar
        const { data } = await Api.delete(`/${baseURL}/${formData.id}`)
        // Filtra para retirar do state
        const items = state.items.filter(item => item.id !== formData.id);

        // Dá o commit no state
        commit('removeItem', items)
        return data;
      } catch (error) {
        return error;
      }
    }

  },

  // Retorna valores existentes no estado
  getters: {
    pagination(state) {
      if (!state.pagination.total) return {
        total: 0, perPage: 20, page: 1, lastPage: 0
      }
      return state.pagination
    },
    all(state) {
      if (state.items && !state.items.length) return []
      return state.items
    },

    unread(state) {
      return state.pagination.unread || 0
    }
  }
};
