import Api from "@/plugins/Api";
const baseURL = 'help/support/categories';

export default {
  // Define que este será acessado via namespace
  namespaced: true,

  // Armazena o estado
  state: {
    // Armazena o usuário autenticado
    items: []
  },

  // Responsável por alterar o estado diretamente
  mutations: {
    updateData(stateObject, data) {
      stateObject.items = data;
    },
    clearState(stateObject) {
      stateObject.items = []
    }
  },

  // Executa upload, consultas a bancos de ddos, apis, etc.
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }) {
      if (state.items.length === 0) {

        await dispatch('refresh')
      }
      return state.items;
    },

    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }) {
      let response = await Api.get(`/${baseURL}`);
      //console.log('refresh')
      if (response.data.error === false) {
        response = response.data.data
      } else {
        response = []
      }
      commit("updateData", response);
    },

    /**
      * Atualiza um registro no store.
      * Se o registro existir, altere.
      * Se não existir, adicione.
      * Chama sort para adicionar os dados ordenados
      */
    async save({ commit, state, dispatch }, { payload, editing }) {
      try {

        let items = state.items;
        // Editando
        if (editing) {
          // 
          const { data: { data } } = await Api.put(`/admin/${baseURL}/${payload.id}`, payload);
          // 
          items = items.map(item => {
            return item.id === payload.id ? data : item
          })

          // Novo
        } else {
          const { data: { data } } = await Api.post(`/admin/${baseURL}`, payload);
          items.push(data)
        }

        dispatch('updateSorted', items)
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza os partidos de forma ordenada pelo nome
     */
    updateSorted({ commit }, items) {
      //console.log('store/chamou sort')
      function compare(a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase())
          return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase())
          return 1;
        return 0;
      }

      commit('updateData', items.sort(compare));
    },


    /**
     * Remove o cadastro
     */
    async remove({ commit, state }, formData) {
      try {
        const { data } = await Api.delete(`/admin/${baseURL}/${formData.id}`);
        return data;
      } catch (error) {
        throw error;
      }
    }

  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },
    full(state) {
      const categories = state.items || []
      const full = [];

      categories.forEach((category, i) => {
        full.push({
          ...category,
          full: category.name,
          root: true
        });

        category.subcategories.forEach((sub, j) => {
          full.push({
            ...sub,
            full: `${category.name} › ${sub.name}`,
            root: false
          });

        });
      });
      return full;
    }
  }
};
