<template>
  <div>
    <v-text-field
      :append-icon="eyeIcon"
      :type="inputType"
      :label="label"
      :value="value"
      :rules="rules"
      :error-messages="errorMessages"
      @click:append="toggle"
      @keypress.enter="onEnter"
      v-on:input="setValue"
      v-bind="$attrs"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "v-password",

  props: {
    value: { type: String, default: null },
    label: { type: String, default: "Senha" },
    rules: Array,
    errorMessages: [String, Array]
  },

  data() {
    return {
      inputType: "password",
      password: null
    };
  },
  computed: {
    eyeIcon() {
      return this.inputType === "password" ? "mdi-eye-off" : "mdi-eye";
    }
  },

  methods: {
    /**
     * Alterna o tipo
     */
    toggle() {
      if (this.inputType === "password") this.inputType = "text";
      else this.inputType = "password";
    },

    /**
     * Envia a senha para o component pai
     */
    setValue(value) {
      this.$emit("input", value);
    },

    /**
     * Ao apertar enter
     */
    onEnter(e) {
      this.$emit("press-enter");
    }
  }
};
</script>

<style scoped>
</style>
