export default [


  {
    path: '/constructions',
    name: 'constructions',
    component: () => import(/* webpackChunkName: "constructions" */ '@/views/Constructions/PageIndex.vue'),
    meta: {
      title: "Obras",
      KMenu: { icon: "mdi-office-building-marker" },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/constructions/add',
    name: 'constructions-add',
    component: () => import(/* webpackChunkName: "constructions" */ '@/views/Constructions/PageSave.vue'),
    meta: {
      title: "Cadastro de Obras",
      backButton: '/constructions',
      KMenu: { parent: 'constructions', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator'],

    }
  },
  {
    path: '/constructions/view/:id',
    name: 'constructions-view',
    component: () => import(/* webpackChunkName: "constructions" */ '@/views/Constructions/PageSave.vue'),
    meta: {
      title: "Obra",
      backButton: '/constructions',
      KMenu: { parent: 'constructions', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator'],

    }
  },

  {
    path: '/constructions/services',
    name: 'constructions-services',
    component: () => import(/* webpackChunkName: "constructions" */ '@/views/Constructions/Services/PageIndex.vue'),
    meta: {
      title: "Serviços",
      backButton: ({ params }) => `/constructions`,
      KMenu: { parent: 'constructions', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator'],

    }
  },

  {
    path: '/constructions/:construction_id/services/add',
    name: 'services-add',
    component: () => import(/* webpackChunkName: "constructions" */ '@/views/Constructions/Services/PageSave.vue'),
    meta: {
      title: "Cadastro de Serviços",
      backButton: ({ params }) => `/constructions/view/${params.construction_id}`,
      KMenu: { parent: 'constructions', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator'],

    }
  },
  {
    path: '/constructions/:construction_id/services/view/:id',
    name: 'services-view',
    component: () => import(/* webpackChunkName: "constructions" */ '@/views/Constructions/Services/PageSave.vue'),
    meta: {
      title: "Serviço",
      backButton: ({ params }) => `/constructions/view/${params.construction_id}`,
      KMenu: { parent: 'constructions', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator'],

    }
  },


]
