export default [


  /**
   * Administra os dados dos logs
   */
  {
    path: '/admin/logs',
    name: 'admin-logs',
    component: () => import(/* webpackChunkName: "logs" */ '@/views/Logs/PageIndex.vue'),
    meta: {
      title: "Logs do Sistema",
      KMenu: { icon: "mdi-format-list-bulleted-type" },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'support']
    }
  },



]
