const storage = localStorage || window.localStorage;
const objectToString = obj => JSON.stringify(obj);
const stringToObject = str => JSON.parse(str);

export default {
  /**
   * Define o valor de uma chave
   *
   * @param {string} key Chave
   * @param {object} value Valor
   */
  set(key, value) {
    return storage.setItem(key, objectToString(value));
  },

  /**
   * Pega o valor de uma chave
   *
   * @param {string} key Chave
   */
  get(key) {
    try {
      return stringToObject(storage.getItem(key));
    } catch (e) {
      return storage.getItem(key);
    }
  },

  /**
   * Apaga uma chave
   *
   * @param {string} key Chave
   */
  delete(key) {
    return storage.removeItem(key);
  },

  /**
   * Limpa a sessão
   *
   */
  clear() {
    return storage.clear();
  }
};
