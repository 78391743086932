import Api from "@/plugins/Api";
import Vue from "vue";

// Controla o token do axios para evitar duas requisições ao mesmo tempo
import axios from "axios";
let axiosCancelToken = null;

const baseUrl = "/financials/connections";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    changeItems(stateObject, payload = {}) {
      stateObject.items = payload;
    },
    createItem(stateObject, payload) {
      stateObject.items.push(payload);
    },
    updateItem(stateObject, payload) {
      const index = stateObject.items.findIndex(item => item.id === payload.id);
      Vue.set(stateObject.items, index, payload);
    },
    clearState(stateObject) {
      stateObject.items = [];
      stateObject.pagination = {};
    },
  },
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit("clearState");
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }) {
      if (state.items.length === 0) {
        await dispatch("refresh");
      }
      return state.items;
    },

    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }, query = {}) {
      try {
        // Cancela a requisição anterior se existir
        if (axiosCancelToken) axiosCancelToken.cancel();

        query.is_active = false;

        // Cria um novo token de cancelamento
        axiosCancelToken = axios.CancelToken.source();
        const options = { cancelToken: axiosCancelToken.token };

        let {
          data: { data },
        } = await Api.get(baseUrl, query, options);

        // console.log(data);

        commit("changeItems", data);
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error;
        }
      }
    },

    /**
     * Atualiza um registro no store.
     */
    async save({ commit, state, dispatch }, { id, payload }) {
      try {
        // let items = state.items || [];
        if (id) {
          // Requisição
          const {
            data: { data },
          } = await Api.patch(`${baseUrl}/${id}`, payload);

          // Altero somente o usuário modificado com os dados retornados da requisição
          commit("updateItem", data);
          return data;
        }else {
          const {
            data: { data },
          } = await Api.post(`${baseUrl}`, payload);

          // Altero somente o usuário modificado com os dados retornados da requisição
          commit("createItem", data);
          return data;
          
        }
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza as configurações de autenticação da conexão
     */
    async updateAuth({ commit, state, dispatch }, { payload, id }) {
      try {
        // Requisição
        await Api.patch(`${baseUrl}/${id}/auth`, payload);
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza as configurações de cobrança da conexão
     */
    async updateSettings({ commit, state, dispatch }, { payload, id }) {
      try {
        // Requisição
        const {
          data: { data },
        } = await Api.patch(`${baseUrl}/${id}/settings`, payload);
        commit("updateItem", data);
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza um registro no store.
     */
    async updateWebhook({}, id) {
      try {
        // Requisição
        await Api.post(`${baseUrl}/${id}/webhook`);
      } catch (error) {
        throw error;
      }
    },
  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },
    allActive(state) {
      return state.items.filter(item => item.is_active);
    },
  },
};
