<template>
  <div>
    <v-img
      cover
      :aspect-ratio="aspectRatio"
      :width="width"
      :height="height"
      :align="align"
      :src="fullPath"
      @load="onLoad"
      @error="onError"
    >
      <v-row
        :align="loading ? 'center' : align"
        class="white--text pa-2 fill-height"
      >
        <v-col class="justify-center d-flex">
          <v-overlay absolute v-if="loading">
            <v-progress-circular
              indeterminate
              :width="1"
              :size="16"
              color="white"
            ></v-progress-circular>
          </v-overlay>

          <slot v-if="!loading"></slot>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "k-image",
  props: {
    src: { type: [String, Boolean] },
    width: [String, Number],
    height: [String, Number],
    //
    aspectRatio: { type: Number, default: 1 },
    // Máximo de tentativas de carregamento da imagem
    maxAttempts: { type: Number, default: 5 },
    // Alinha o conteúdo verticamente: start, center, end
    align: { type: String, default: "end" },
    // Usa um timestamp na url para fazer as tentativas de carregamento mais forçada
    useSuffix: Boolean,
    // Usa um timeout para personalizar o tempo
    timeout: { type: Number, default: 1000 }
  },

  data() {
    return {
      settings: {
        aspectRatio: 1,
        width: 400,
        height: 400,
        contain: true
      },

      loading: false,
      countAttempts: 0
    };
  },

  watch: {
    src() {
      if (!this.src) return "";
      this.countAttempts = 0;
      this.refresh();
    }
  },

  computed: {
    fullPath() {
      if (this.loading) {
        return "";
      }

      if (this.useSuffix) {
        return this.src + "?" + Date.now();
      }
      return this.src; // + "?" + Date.now();

      // if (this.countAttempts <= 1) {
      // }
    }
  },

  methods: {
    /**
     * Faz uma tentativa de atualizar a imagem.
     * O número de tentativas será limitado à propriedade maxAttempts, caso não seja forçado
     *
     * Caso o force seja positivo, várias tentativas podem ser feitas
     */
    refresh(force = false) {
      if (this.countAttempts >= this.maxAttempts && !force) return;
      this.countAttempts++;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, this.timeout);
    },

    /**
     * Executado quando a imagem estiver sendo carregada
     */
    onLoad() {
      this.loading = false;
    },
    /**
     * Executado quando a imagem estiver sendo carregada
     */
    onError(e) {
      this.refresh();
    }
  },

  mounted() {
    this.refresh();
  }
};
</script>