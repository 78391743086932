import { render, staticRenderFns } from "./KForm.vue?vue&type=template&id=61b1246d&scoped=true"
import script from "./KForm.vue?vue&type=script&lang=js"
export * from "./KForm.vue?vue&type=script&lang=js"
import style0 from "./KForm.vue?vue&type=style&index=0&id=61b1246d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b1246d",
  null
  
)

export default component.exports