export default [

  /**
   * Gerenciamento de solicitações de coleta
   */
  { // Listagem completa das solicitações de coleta
    path: '/collect/collections',
    name: 'collect-collections-index',
    component: () => import(/* webpackChunkName: "collections" */ '@/views/Collect/collections/PageIndex.vue'),
    meta: {
      title: "Solicitações de Coleta",
      KMenu: { parent: 'collect', show: false },
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },
  { // Cria coleta em lote
    path: '/collect/collections/batch',
    name: 'collect-collections-batch',
    component: () => import(/* webpackChunkName: "collections" */ '@/views/Collect/collections/PageBatch.vue'),
    meta: {

      title: "Novo Lote de Solicitações",
      KMenu: { parent: 'collect', show: false },
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  { // Visualiza uma solicitação de coleta
    path: '/collect/collections/view/:id',
    name: 'collect-collections-view',
    component: () => import(/* webpackChunkName: "collections" */ '@/views/Collect/collections/PageView.vue'),
    meta: {
      backButton: true,
      title: "Solicitação de Coleta",
      KMenu: { parent: 'collect', show: false },
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },
]
