const PostalCodes = {

  /**
   * Multipliers
   */
  multipliers: [ 8, 6, 4, 2, 3, 5, 9, 7 ],

  /**
   * Is a postal register number?
   * 
   * @example
   * isValid( 'RU866808761CN' )
   * // true
   * isValid( 'RU866808760CN' )
   * // false
   * 
   * @param {String} number Postal Register Number in format AA123456789BB
   */
  isValid ( number ) {
    const parts = this.parse ( number )
        
    if ( parts )
      return this.getCheckingNumber( parts.number ) === parts.checkingNumber;

    return false;
  },

  /**
   * Gets a checking number from a given postal code parsed.
   * @example
   * To calculate checking number from RU866808761CN, pass to '86680876'
   * getCheckingNumber( 86680876 ) 
   * // 1
   * 
   * @param {String|Integer} number Postal Register Number in format 12345678
   */
  getCheckingNumber ( number ) {
    // Check if is string or integer
    if ( typeof number !== 'string' && typeof number !== 'integer' ) return null;

    // Regex
    const rgx = /^([0-9]{8})$/i;

    if ( rgx.test( number ) ) {
      const multipliers  = [ 8, 6, 4, 2, 3, 5, 9, 7 ];
      const arrayNumbers = [ ...number ];
      
      const sum = arrayNumbers
        .map( ( cur, i ) => cur * multipliers[ i ] )
        .reduce( ( accu, cur ) => accu + cur )

      // Rest calculus
      const rest = sum % 11;

      // 
      if ( rest === 0 ) return '5';
      else if ( rest === 1 ) return '0';
      else return ( 11 - rest ).toString();
    } else {
      return null;
    }
  },


  /**
   * Parses a postal register number
   * 
   * @example
   * parse( 'RU866808761CN' ) 
   * // { type: 'RU', number: '86680876', checkingNumber: '1', country: 'BR' }
   * 
   * parse( 'KLAWDYO' )
   * // false
   * 
   * @param {String} number Postal Register Number in format AA123456789BB
   */
  parse ( number ) {
    
    const rgx = /([a-z]{2})([0-9]{8})([0-9]{1})([a-z]{2})/i;

    const parts = rgx.exec( number );
    // console.log(parts);
    
    if ( parts && parts.length === 5 ) {
      const groups = {
        type: parts[1],
        number: parts[2],
        checkingNumber: parts[3],
        country: parts[4],
      }
      return groups || false;
    }

    return false;


  }

}

// module.exports = PostalCodes;
export default PostalCodes;