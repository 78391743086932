import Api from "@/plugins/Api";
const baseURL = '/stats'

export default {
  // Define que este será acessado via namespace
  namespaced: true,

  // Armazena o estado
  state: {
    // Armazena o usuário autenticado
    data: {},
    loaded: false

  },

  // Responsável por alterar o estado diretamente
  mutations: {
    updateData(stateObject, data) {
      stateObject.data = data;
      if (data) {
        console.log('dados dentro do store/dashboard')
        stateObject.loaded = true;
      }
    },
    clearState(stateObject) {
      stateObject.data = {}
      stateObject.loaded = false
    }
  },

  // Executa upload, consultas a bancos de ddos, apis, etc.
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }, { admin = false }) {
      if (!state.loaded) {
        await dispatch('refresh', { admin })
      }
      return state.data;
    },

    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }, { admin = false }) {
      try {
        let url;
        if (admin) url = '/admin/stats/dashboard'
        else url = '/stats/dashboard'

        let { data: { data } } = await Api.get(url);
        commit("updateData", data);
      } catch (error) {
        throw error
      }
    },



  },

  // Retorna valores existentes no estado
  getters: {
    dashboard(state) {
      if (state.loaded) {
        return state.data
      }
      return {
        users_by_day: {},
        customizations_by_day: {},
        press_items_by_day: {},
        gov_plan_downloads_by_day: {},
        media_downloads_by_day: {},
        social_networks_by_day: {},
        // 
        online_users: 0,
        users_in_last_24_hours: 0,
        // 
        disk_usage: {},
        users_by_time: {},
        users_by_week_day: {},
        // Exclusivo para Candidato
        better_rating_faqs: [],
        most_used_image_customizations: [],
        most_opened_press_items: [],
        most_opened_social_networks: [],
        most_opened_media: [],
        // Exclusivo para administradores
        users_by_browser: [],
        users_by_country: [],
        users_by_city: [],
        users_by_os: [],
        most_popular_candidate: [],

      }
    }
  },
};
