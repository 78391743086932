/**
 * Este plugin de API servirá para concentrar as requisições
 * à API.
 * Atribuições:
 * - Definir o ambiente e mudar a URL da API apropriadamente
 * - Se conectar com o Login, passwordReminder e Logout
 * - Adicionar a chave de autenticação quando fizer login,
 *   acessá-la nas demais requisições e removê-la ao fazer logoff
 * - Realizar todas as requisições, passando o cabeçalho de autenticação
 */

// Importação de bibliotecas oficiais
// const storage = sessionStorage || window.sessionStorage;

// Importação de Bibliotecas de Terceiros
import axios from "axios";
import { store } from "../store";
import router from "../router";

// import Router from "vue-router";

// Importação de Bibliotecas Internas
import Bus from "./GlobalInteractions";
import Storage from "./Storage";

// Variáveis
const tokenName = "g3dobrasil_token";
const userDataName = "g3dobrasil_userdata";

const api = {
  environment: process.env.VUE_APP_ENV,
  baseURL: process.env.VUE_APP_API_URL,
  siteURL: process.env.VUE_APP_SITE_URL,
}

api.url = (url, query = {}) => {
  const queryString = new URLSearchParams(query).toString();

  if (queryString) {
    return `${normalizeURL(url)}?${queryString}`;
  }

  return normalizeURL(url) + '?' + queryString;
}

api.get = (url, data = {}, options = {}) => axios.get(normalizeURL(url), {
  params: data,
  headers: { Authorization: "Bearer " + store.getters['auth/token'] },
  ...options
})

api.post = (url, data) => axios.post(normalizeURL(url), data, {
  headers: { Authorization: "Bearer " + store.getters['auth/token'] }
})

api.put = (url, data) => axios.put(normalizeURL(url), data, {
  headers: { Authorization: "Bearer " + store.getters['auth/token'] }
})

api.patch = (url, data) => axios.patch(normalizeURL(url), data, {
  headers: { Authorization: "Bearer " + store.getters['auth/token'] }
})

api.delete = (url, data) => axios.delete(normalizeURL(url), {
  headers: { Authorization: "Bearer " + store.getters['auth/token'] },
  data: data
})


import FileDownloader from 'js-file-download'
api.download = async (url, payload, filename) => {
  const response = await api.get(url, payload, {
    responseType: 'blob', // Important
  })

  FileDownloader(response.data, filename)
}

/**
 * Realiza o upload de um arquivo para o endereço definido
 * @param url {string} URL para onde será enviado
 * @param file {string} O objeto do arquivo
 * @param options {object}: Opções de personalização do método
 *  - fieldName: É o nome do arquivo que será enviado. O padrão é 'image'
 *  - method: Método HTTP usado no upload. O padrão é 'post'
 */
api.upload = (url, file, options = {}) => {
  let formData = new FormData();

  // console.log(url, file);

  const { fieldName = 'image', method = 'post', data = {} } = options

  formData.append(fieldName, file);

  const fields = Object.keys(data);
  if (fields.length) {
    fields.forEach(key => formData.append(key, data[key]))
  }

  return axios({
    method,
    url: `${api.baseURL}${url}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + store.getters['auth/token']
    }
  })
}


/**
 * Pega o usuário logado
 */
api.getCurrentUser = () => {
  // return Storage.get(userDataName);
  return store.getters['auth/user'];
};

/**
 * Pega as permissões do usuário logado
 */
api.getCurrentRoles = () => {
  const roles = api.isLoggedIn()
    ? ["logged", ...(store.getters['auth/roles'] || [])]
    : ["guest"];

  // store.dispatch("roles/update", { roles });

  return roles;
};

/**
 * Verifica se o usuário está logado
 */
api.isLoggedIn = () => {
  // return !!Storage.get(tokenName);
  return store.getters['auth/token']
};

/**
 * Login será uma das únicas partes da API que será usada sem necessitar
 * de autenticação
 *
 * @param {string} user A identificação do usuário, podendo ser um e-mail ou um CPF
 * @param {string} password A senha
 */
api.login = async ({ email, password }) => {

  try {
    // Usa o axios para não enviar o cabeçalho das demais requisições
    const result = await axios.post(`${api.baseURL}/auth/login`, {
      email,
      password
    });
    const { data } = result;

    // Se existir token
    if (data.data.token) {

      // Atualiza o store
      updateStore(result.data.data)

    } else {
      // console.log("eeerrrooo-->", { data });
    }

    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * Relembrar senha é um dos únicos caminhos da API que pode ser acessado sem autenticação,
 * por isso estou separando da API original, onde todas as requisições passarão autenticação
 */
api.passwordReminder = async ({ user }) => {
  const result = await axios.post(`${api.baseURL}/auth/password_reminder`, {
    user
  });

  return result;
};

/**
 * Faz logoff, removendo o
 */
api.logout = () => {
  // Limpa o token da sessão e redireciona para o login
  api.clearAll()
  // Redireciona para o login
  router.push('/login');
};

/**
 * Limpa os tokens e o estado
 */
api.clearAll = () => {
  // Remove o token
  Storage.delete(tokenName);
  // Efetua o logout
  store.dispatch("auth/logout");
  // Limpa todo o estado da aplicação
  store.dispatch("clearAllState");

}

/**
 * Valida um token ao acessar uma página diretamente pela URL. 
 * Caso não exista token, redirecione para a página de login.
 */
api.validateToken = (StringToken = null) => {
  try {
    // Pega qualquer erro
    const token = StringToken || Storage.get(tokenName);
    // Adiciona o token no store, caso já não tenha
    if (!store.getters['auth/token']) {
      store.dispatch('auth/setToken', token);
    }
    // Monta o link da busca e força o cabeçalho da autenticação pois diretamente
    // pela api estava dando muito erro
    return axios.post(api.baseURL + '/auth/validate_token', null, {
      headers: { Authorization: "Bearer " + token }
    })
      .then(response => {
        // Exibe a mensagem
        Bus.message.success('Usuário autenticado com sucesso')

        // Pega os dados que realmente interessam
        const user = response.data.data
        updateStore({ token, user })
        return user;
      })
      .catch(error => {

        // Mensagem de Erro
        Bus.message.error('Erro ao validar o token. Tente novamente')
        // Efetua o logout para limpar qualquer vestígio de um usuário logado
        api.clearAll()
      })
  } catch (error) {
    // Exibe a mensagem de erro
    Bus.message.error('Erro desconhecido ao validar o token.')
    // Efetua o logou para limpar qualquer vestígio de um usuário logado
    api.clearAll()
  }
};

/**
 * Valida um token do SUAP
 */
api.validateSUAPToken = async (suapToken = null) => {
  try {
    // Monta o link da busca e força o cabeçalho da autenticação pois diretamente
    // pela api estava dando muito erro
    const response = await axios.post(api.baseURL + '/auth/validate_suap_token', {
      access_token: suapToken
    })

    // Atualiza o store
    updateStore(response.data.data)
    return response
  } catch (error) {
    // Efetua o logou para limpar qualquer vestígio de um usuário logado
    api.clearAll()
    throw error
  }
};

function normalizeURL(url) {
  if (/^https?\:\/\//.test(url)) {
    return url
  } else {
    return `${api.baseURL}${url}`
  }
}

/**
 * A partir de um token e dos dados do usuário retornados da API, cadastra 
 * as informações no store e no session do navegador
 */
function updateStore(data) {
  // diciona o token no localstorage
  Storage.set(tokenName, data.token);
  // Adiciona o token no store
  store.dispatch('auth/setToken', data.token);
  // Roles
  const roles = ['logged', ...data.user.roles.map(role => role.slug)]
  // Atualiza o usuário no store
  store.dispatch('auth/setUser', data.user)
  store.dispatch('auth/setRoles', roles)
}

export default api;
