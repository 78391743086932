import Api from "@/plugins/Api";
import Vue from 'vue'
const baseURL = 'roles';

export default {
  // Define que este será acessado via namespace
  namespaced: true,

  // Armazena o estado
  state: {
    // Armazena a lista de itens
    items: [],
  },

  // Responsável por alterar o estado diretamente
  mutations: {
    changeData(stateObject, data) {
      stateObject.items = data;
    },
    addItem(stateObject, payload) {
      stateObject.items.push(payload);
    },
    editItem(stateObject, payload) {
      const index = stateObject.items.findIndex(item => item.id === payload.id)
      Vue.set(stateObject.items, index, payload)
    },
    removeItem(stateObject, payload) {
      const index = stateObject.items.findIndex(item => item.id === payload.id)
      Vue.delete(stateObject.items, index)
    },
    clearState(stateObject) {
      stateObject.items = []
    }
  },
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ state, dispatch }) {
      if (state.items.length === 0) {
        await dispatch('refresh')
      }
      return state.items;
    },

    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }) {
      try {
        const { data: { data } } = await Api.get(`/admin/iam/${baseURL}`, { order_field: 'name' });
        commit("changeData", data);
      } catch (error) {
        throw error
      }
    },

    /**
     * Atualiza um registro no store.
     * Se o registro existir, altere.
     * Se não existir, adicione.
     * Chama sort para adicionar os dados ordenados
     */
    async save({ commit, state, dispatch }, { formData, editing }) {
      try {
        // Editando
        if (editing) {
          const { data: { data } } = await Api.put(`/admin/${baseURL}/${formData.id}`, formData)
          commit('editItem', data)
          // Novo
        } else {
          const { data: { data } } = await Api.post(`/admin/${baseURL}`, formData)
          commit('addItem', data)
        }
      } catch (error) {
        throw error;
      }
    },

    /**
     * Remove um registro
     */
    async remove({ commit, state }, { id }) {
      try {
        await Api.delete(`/admin/${baseURL}/${id}`)
        commit('removeItem', { id })
      } catch (error) {
        throw error;
      }
    }

  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },
    list(state) {
      if (state.items) {
        return state.items.map(item => item.slug)
      }
      return []
    }
  }
};
