
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import Storage from './Storage'

Vue.use(Vuetify);

import pt from 'vuetify/lib/locale/pt'

// Pegando a informação do localstorage
const dark = Storage.get('theme:dark')

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt'
  },
  theme: {
    dark: !!dark,
    themes: {
      light: {
        primary: colors.grey.darken4, //'#d31d1d',
        secondary: colors.red.darken4,
        accent: colors.amber.darken1,
        error: colors.red.base,
        neutral: colors.grey.lighten5
      },
      dark: {
        primary: colors.grey.lighten5, //'#d31d1d',
        secondary: colors.red.base,
        accent: colors.amber.darken1,
        error: colors.red.base,
        neutral: colors.grey.darken3
      },
    },
  },
});
