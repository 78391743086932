import Api from "@/plugins/Api";
import Vue from 'vue';

// Controla o token do axios para evitar duas requisições ao mesmo tempo
import axios from 'axios'
let axiosCancelToken = null;

export default {
  namespaced: true,
  state: {
    items: [],
    pagination: {}

  },
  mutations: {
    changeUsers(stateObject, { data, ...pagination }) {
      stateObject.items = data;
      if (Object.keys(pagination).length)
        stateObject.pagination = pagination;
    },
    updateItem(stateObject, user) {
      const index = stateObject.items.findIndex(item => item.id === user.id);
      console.log('index', index);
      Vue.set(stateObject.items, index, user);
    },

    clearState(stateObject) {
      stateObject.items = []
      stateObject.pagination = {}
    }
  },
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }) {
      if (state.items.length === 0) {

        await dispatch('refresh')
      }
      return state.items;
    },


    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }, query = {}) {
      try {
        // Cancela a requisição anterior se existir
        if (axiosCancelToken) axiosCancelToken.cancel()

        // Cria um novo token de cancelamento
        axiosCancelToken = axios.CancelToken.source();
        const options = { cancelToken: axiosCancelToken.token }

        query.showUser = true
        query.showContacts = true

        let { data: { data } } = await Api.get('/people', query, options);
        commit("changeUsers", data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Requisição cancelada')
        } else {
          throw error
        }
      }
    },

    /**
     * Atualiza um registro no store.
     * Se o registro existir, altere.
     * Se não existir, adicione.
     * Chama sort para adicionar os dados ordenados
     */
    async save({ commit, state, dispatch }, { payload, editing }) {
      try {

        let users = state.items;

        console.log('dentro do store', { payload });


        // Editando
        if (editing) {
          // Requisição
          const { data: { data } } = await Api.put(`/people/${payload.id}`, payload)
          // Altero somente o usuário modificado com os dados retornados da requisição
          dispatch('updateUserInList', data)
          return data

          // Novo
        } else {
          const { data: { data } } = await Api.post('/people', payload)
          users.push(data)
          dispatch('updateSorted', users)
          return data;
        }

      } catch (error) {
        throw error;
      }
    },


    /**
     * Administrador reseta a senha de um usuário
     * /users/reset_password/:id
     */
    async adminResetPassword({ commit, state }, payload = {}) {
      try {
        const { user_id, ...form } = payload

        const { data: { data } } = await Api.patch(`/users/${user_id}/reset_password`, form)

        if (data) {
          let items = state.items;

          // Alterando o número de logs do usuário
          const item = items.find(item => item.user && item.user.id === user_id)

          // Coloca um valor no log para aparecer o badge
          if (item.user.__meta__) {
            Vue.set(item.user.__meta__, 'logs_count', 1)
          }
        }

        return data;
      } catch (error) {
        console.log('Erro no adminreset', error)
        throw error;
      }
    },

    /**
     * Transforma uma pessoa em usuário
     */
    async handle_user({ commit, state }, { payload, mode }) {
      try {
        const items = state.items;

        let data = {}

        if (mode === 'add') {
          ({ data } = await Api.post('/users', payload))
        }
        //
        else if (mode === 'edit') {
          ({ data } = await Api.patch(`/users/${payload.user_id}`, payload))
        }

        // Pesquisa pelos usuários do store a partir do person_id retornado pela api
        const item = items.find(row => row.id === data.data.person_id)

        // Atualiza os dados do usuário no store
        item.user = data.data;

        // Coloca um valor no log para aparecer o badge
        item.user.__meta__ = {}
        Vue.set(item.user.__meta__, 'logs_count', 1)


      } catch (error) {
        console.log(error);
        throw error;
      }
    },




    /**
     * Atualiza os dados de um usuário na lista de usuários
     */
    updateUserInList({ dispatch, state }, user) {
      let users = state.items;
      // Pesquisando o usuário atual no store e alterando somente ele

      users = users.map(item => {
        return item.id === user.id ? user : item
      })

      dispatch('updateSorted', users);
    },


    /**
     * Atualiza os partidos de forma ordenada pelo nome
     */
    updateSorted({ commit }, users) {
      //console.log('store/chamou sort')
      function compare(a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase())
          return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase())
          return 1;
        return 0;
      }

      commit('changeUsers', { data: users.sort(compare) });
    },


  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },
    pagination(state) {
      return state.pagination || {};
    },
    legalNatures() {
      return [{
        key: 'F',
        name: 'Pessoa Física',
      }, {
        key: 'J',
        name: 'Pessoa Jurídica',
      }]
    }
  }
};
