
export const routesHelp = [


  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  AJUDA
  //  Tópicos de ajuda para os usuários e os administradores
  //
  // ///////////////////////////////////////////////////////////////////////////////
  {
    name: 'help',
    path: '',
    meta: {
      title: "Ajuda",
      KMenu: { icon: "mdi-help", opened: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["logged"]
    }
  },


  {
    path: '/help/faqs',
    name: 'faq',
    component: () => import(/* webpackChunkName: "help" */ '@/views/Faqs/PageIndex.vue'),
    meta: {
      title: "Perguntas Frequentes",
      KMenu: { parent: 'help' },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["logged"]
    }
  },
  {
    path: '/help/faqs/faq/:id',
    name: 'faq_item',
    component: () => import(/* webpackChunkName: "help" */ '@/views/Faqs/PageView.vue'),
    meta: {
      title: "Perguntas Frequentes",
      KMenu: { parent: 'help', show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["logged"]
    }
  },
  {
    path: '/help/faqs/category/:id',
    name: 'faq_category',
    component: () => import(/* webpackChunkName: "help" */ '@/views/Faqs/PageCategory.vue'),
    meta: {
      title: "Perguntas Frequentes",
      KMenu: { parent: 'help', show: false },
      backButton: '/help/faqs',
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["logged"]
    }
  },


]

export const routesAdminHelp = [

  /**
     * FAQ - Admin
     */
  {
    path: '/admin/help/faqs',
    name: 'admin_faq',
    component: () => import(/* webpackChunkName: "admin_faq" */ '@/views/Faqs/admin/PageAdminIndex.vue'),
    meta: {
      title: "FAQ - Admin",
      KMenu: { parent: 'settings' },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin"]
    }
  },



  /**
   * Categorias de FAQs
   */
  {
    path: '/admin/settings/faq_categories',
    name: 'faqs_categories',
    component: () => import(/* webpackChunkName: "admin_faq" */ '@/views/Faqs/categories/PageIndex.vue'),
    meta: {
      title: "FAQ - Categorias",
      KMenu: { parent: 'settings' },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin"]
    }
  },


]
