import Api from "@/plugins/Api";
import Vue from 'vue';


// Controla o token do axios para evitar duas requisições ao mesmo tempo
import axios from 'axios'
let axiosCancelToken = null;

const baseUrl = '/constructions/statuses'

export default {
  namespaced: true,
  state: {
    items: []

  },
  mutations: {
    changeItems(stateObject, data) {
      stateObject.items = data;

    },


    clearState(stateObject) {
      stateObject.items = []
    }
  },
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }) {
      if (state.items.length === 0) {

        await dispatch('refresh')
      }
      return state.items;
    },


    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }, query = {}) {
      try {
        // Cancela a requisição anterior se existir
        if (axiosCancelToken) axiosCancelToken.cancel()

        // Cria um novo token de cancelamento
        axiosCancelToken = axios.CancelToken.source();
        const options = { cancelToken: axiosCancelToken.token }

        let { data: { data } } = await Api.get(baseUrl, query, options);
        commit("changeItems", data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Requisição cancelada')
        } else {
          throw error
        }
      }
    },



  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },

  }
};
