import Api from "@/plugins/Api";
const baseURL = 'auth';

export default {
  // Define que este será acessado via namespace
  namespaced: true,

  state: {
    items: []
  },

  mutations: {
    clearState(stateObject) {
      stateObject.items = []
    }
  },


  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },
    /**
     * Envia o e-mail de recuperação
     */
    async sendReminder({ commit }, payload) {
      try {
        const { data } = await Api.post(`/${baseURL}/recovery`, payload);
        return data;
      } catch (error) {
        throw error
      }
    },

    /**
     * Envia o código recebido para validação
     */
    async validateToken({ commit }, payload) {
      try {
        const { data } = await Api.post(`/${baseURL}/validate_recovery`, payload);
        return data;
      } catch (error) {
        throw error
      }
    },

    /**
     * Envia as senhas para fazer a alteração
     */
    async changePassword({ commit }, payload) {
      try {
        const { data } = await Api.post(`/${baseURL}/change_password`, payload);
        return data;
      } catch (error) {
        throw error
      }
    },
  },
};
