import Api from "@/plugins/Api";
import Vue from "vue";

// Controla o token do axios para evitar duas requisições ao mesmo tempo
import axios from "axios";
let axiosCancelToken = null;

const baseUrl = "/financials";

export default {
  namespaced: true,
  state: () => ({
    items: [],
    pagination: {},
    resume: {},
  }),
  mutations: {
    changeItems(stateObject, payload = {}) {
      const { data, resume, ...pagination } = payload;
      stateObject.items = data;
      if (Object.keys(pagination).length) stateObject.pagination = pagination;
    },
    addItem(stateObject, payload) {
      stateObject.items.push(payload);
    },
    updateResume(stateObject, payload) {
      stateObject.resume = payload;
    },
    clearState(stateObject) {
      stateObject.items = [];
      stateObject.pagination = {};
    },
  },
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit("clearState");
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }) {
      if (state.items.length === 0) {
        await dispatch("refresh");
      }
      return state.items;
    },

    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }, query = {}) {
      try {
        // Cancela a requisição anterior se existir
        if (axiosCancelToken) {
          // console.log('cancel', axiosCancelToken);
          axiosCancelToken.cancel();
        }

        // Cria um novo token de cancelamento
        axiosCancelToken = axios.CancelToken.source();
        const options = { cancelToken: axiosCancelToken.token };

        let {
          data: { data },
        } = await Api.get(baseUrl, query, options);
        commit("changeItems", data);
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error;
        }
      }
    },

    /**
     * Atualiza o resumo no store
     */
    async updateResume({ commit, state, dispatch }, params) {
      try {
        // Requisição
        const { data } = await Api.get(`${baseUrl}/resume`, params);

        commit("updateResume", data.data);
      } catch (error) {
        throw error;
      }
    },

    async forceUpdate({ commit, state, dispatch }, id) {
      try {
        // Requisição
        const { data } = await Api.patch(`${baseUrl}/charges/${id}/force`);

        dispatch("updateItemInList", data.data);
        return data;
      } catch (error) {
        throw error;
      }
    },

    async duplicate({ commit, state, dispatch }, { id, payload }) {
      try {
        // Requisição
        const {
          data: { data },
        } = await Api.post(`${baseUrl}/charges/${id}/duplicate`, payload);

        commit("addItem", data);

        // // Atualizo o item antigo
        // const {
        //   data: { data: oldItem },
        // } = await Api.get(`${baseUrl}/${id}`);

        // console.log("oldItem atualizaado", oldItem);
        // dispatch("updateItemInList", oldItem);

        return data;
      } catch (error) {
        throw error;
      }
    },

    async cancel({ commit, state, dispatch }, id) {
      try {
        // Requisição
        const { data } = await Api.patch(`${baseUrl}/charges/${id}/cancel`);

        dispatch("updateItemInList", data.data);
        return data;
      } catch (error) {
        throw error;
      }
    },

    async disassociate({ commit, state, dispatch }, id) {
      try {
        // Requisição
        const { data } = await Api.patch(
          `${baseUrl}/charges/${id}/disassociate`
        );

        dispatch("updateItemInList", data.data);
        return data;
      } catch (error) {
        throw error;
      }
    },

    // Adiciona o arquivo no store
    async addFile({ state }, { id, file }) {
      try {
        // Pega a referência do item
        const item = state.items.find((item) => item.id === id);
        // Se existir, adicione o arquivo
        if (item && item.files && Array.isArray(item.files)) {
          item.files.push(file);
        }
      } catch (error) {
        throw error;
      }
    },

    //
    async billetGenerate({ commit, state, dispatch }, public_id) {
      try {
        const {
          data: { data },
        } = await Api.get(`${baseUrl}/charges/${public_id}/billet`);

        dispatch("updateItemInList", data.charge);

        return data;
      } catch (error) {
        throw error;
      }
    },

    /**
     * Cadastra uma cobrança
     */
    async addCharge({ commit, state, dispatch }, payload) {
      try {
        const {
          data: { data },
        } = await Api.post(`${baseUrl}/charges`, payload);
        commit("addItem", data);
        return data;
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza um registro no store.
     * Se o registro existir, altere.
     * Se não existir, adicione.
     * Chama sort para adicionar os dados ordenados
     */
    async save({ commit, state, dispatch }, payload) {
      try {
        let items = state.items || [];

        // Editando
        if (payload.id) {
          console.log("entrou na edição");
          // Requisição
          const {
            data: { data },
          } = await Api.put(`${baseUrl}/${payload.id}`, payload);
          console.log(data);
          // Altero somente o usuário modificado com os dados retornados da requisição
          dispatch("updateItemInList", data);
          return data;

          // Novo
        } else {
          const {
            data: { data },
          } = await Api.post(baseUrl, payload);
          commit("addItem", data);
          return data;
        }
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza os dados de um usuário na lista de usuários
     */
    updateItemInList({ commit, state }, payload) {
      let items = state.items;
      // Pesquisando o item atual no store e alterando somente ele

      items = items.map((item) => {
        return item.id === payload.id ? payload : item;
      });

      commit("changeItems", { data: items });
    },
  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },
    pagination(state) {
      return state.pagination || {};
    },
    multipliers() {
      return [
        {
          value: "1",
          text: "Receitas",
        },
        {
          value: "-1",
          text: "Despesas",
        },
      ];
    },
    modules() {
      return [
        {
          value: "collect",
          text: "Coletas",
        },
        {
          value: "supply",
          text: "Abastecimentos",
        },
        {
          value: "none",
          text: "Nenhum",
        },
      ];
    },
    searchResume(state) {
      return state.resume;
    },
  },
};
