<template>
  <v-container class="flex fill-height align-content-center">
    <v-row class="align-end">
      <v-col cols="12" class="pa-0 align-center">
        <v-responsive
          width="100%"
          :height="140"
          class="d-flex align-center justify-center"
        >
          <v-img
            v-if="$vuetify.theme.dark"
            class="text-center mx-auto"
            :width="140"
            :height="140"
            src="/logo-mono-light.svg"
          />
          <v-img
            v-else
            class="text-center mx-auto"
            :width="140"
            :height="140"
            src="/favicon.svg"
          />
        </v-responsive>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col align="center">
        <v-card outlined width="320" elevation="0">
          <v-card-title class="justify-center">{{ title }}</v-card-title>
          <v-card-subtitle v-if="subtitle" class="text-center">{{
            subtitle
          }}</v-card-subtitle>
          <v-card-text>
            <slot></slot>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- <slot></slot> -->
  </v-container>
</template>

<script>
export default {
  name: "k-page",

  props: {
    title: String,
    subtitle: String
  }
};
</script>

<style>
</style>
