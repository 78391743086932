<template>
  <KPage title="Login">
    <v-row class="my-0">
      <!-- Login com usuário e senha -->
      <v-col cols="12">
        <k-form ref="form">
          <v-row>
            <!-- Email -->
            <v-col cols="12" class="py-0 px-4 my-2">
              <v-text-field
                outlined
                dense
                persistent-hint
                v-model="email"
                :rules="[$validation.isRequired, $validation.isEmail]"
                label="E-mail"
              ></v-text-field>
            </v-col>
            <!-- Senha -->
            <v-col cols="12" class="py-0 px-4">
              <v-password
                outlined
                dense
                v-model="password"
                :rules="[$validation.isRequired]"
                @press-enter="doLogin"
                label="Senha"
                persistent-hint
              ></v-password>
            </v-col>
            <!-- Botão de entrar -->
            <v-col cols="12">
              <v-btn
                rounded
                text
                block
                color="secondary"
                @click="doLogin"
                :loading="loading.login"
                >Entrar</v-btn
              >
            </v-col>
          </v-row>
        </k-form>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col cols="12" class="text-center pa-0 pb-2 caption">
        <router-link to="/auth/recovery"
          >Esqueci minha senha</router-link
        ></v-col
      >
    </v-row>
    <!--  -->
    <v-row>
      <v-col cols="12" class="text-center pa-0 pb-2 caption"
        >Ao entrar, você concorda com a nossa
        <router-link to="/privacidade"
          >política de privacidade</router-link
        ></v-col
      >
    </v-row>
  </KPage>
</template>

<script>
import VPassword from "@/components/VPassword";
import KPage from "@/components/KPage/auth";
import KForm from "@/components/KForm";
import "./style.css";

export default {
  components: { VPassword, KForm, KPage },
  data() {
    return {
      email: null,
      password: null,
      loading: {
        login: false
      },
      visible: false
    };
  },

  computed: {},
  // Métodos
  methods: {
    /**
     * Realiza login por meio de email ou cpf
     */
    doLogin() {
      try {
        // Valida
        if (!this.$refs.form.validate()) {
          this.$message.error("Verifique os campos em vermelho");
          return;
        }

        // Ativa o loading
        this.loading.login = true;

        // Faz o login
        this.$api
          .login({ email: this.email, password: this.password })
          .then(result => {
            this.loading.login = false;
            this.$message.success(result.message);
            // Próxima rota após login
            this.resolveNextRoute();
          })
          .catch(error => {
            this.loading.login = false;
            this.$message.serverError(error);
          });
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Decide qual a próxima rota que será feita após o login.
     * caso exista o parâmetro next na url, use-o para determinar a próxima rota
     */
    resolveNextRoute() {
      const next = this.$route.query.next || "/";
      this.visible = false;
      // setTimeout(() => {
      this.$router.replace(next);
      // }, 000);
    }
  },

  mounted() {
    setTimeout(() => {
      this.visible = true;
    }, 1000);
  }
};
</script>
