
// Condomínios
import condominiums from './condominiums'

// Contratos de Consumo
import contracts from './contracts'

// Coletas
import collections from './collections'

// Abastecimentos
import supplies from './supplies'

// COletor
import collector from './collector'

export default [


  {
    path: '/collect',
    name: 'collect',
    component: () => import(/* webpackChunkName: "collect" */ '@/views/Collect/dashboard/PageIndex.vue'),
    meta: {
      title: "Coleta Individualizada",
      KMenu: { icon: "mdi-pipe-valve" },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  ...condominiums,

  ...contracts,

  ...collections,

  ...supplies,

  ...collector
]
