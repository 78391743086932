export default [


  ////////////////
  //
  // ABASTECIMENTOS
  //
  ////////////////


  {
    path: '/collect/supplies',
    name: 'collect-supplies-list',
    component: () => import(/* webpackChunkName: "supplies" */ '@/views/Collect/supplies/PageIndex.vue'),
    meta: {
      title: "Abastecimentos",
      backButton: '/collect',
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/collect/condominiums/:condominium_id/supplies',
    name: 'collect-condominiums-view',
    component: () => import(/* webpackChunkName: "supplies" */ '@/views/Collect/supplies/PageCondominiums.vue'),
    meta: {
      title: "Abastecimentos",
      backButton: ({ params }) => `/collect/condominiums/view/${params.condominium_id}`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/collect/condominiums/:condominium_id/supplies/add',
    name: 'collect-supplies-condomonium-add',
    component: () => import(/* webpackChunkName: "supplies" */ '@/views/Collect/supplies/PageView.vue'),
    meta: {
      title: "Novo Abastecimento",
      backButton: ({ params }) => `/collect/condominiums/${params.condominium_id}/supplies`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/collect/supplies/add',
    name: 'collect-supplies-condomonium-add',
    component: () => import(/* webpackChunkName: "supplies" */ '@/views/Collect/supplies/PageAdd.vue'),
    meta: {
      title: "Novo Abastecimento",
      backButton: ({ params }) => `/collect`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },


  // Rota para acesso a partir de um abastecimento de condomínio, informando o condomínio 
  // na url
  {
    path: '/collect/condominiums/:condominium_id/supplies/view/:supply_id',
    name: 'collect-supplies-view',
    component: () => import(/* webpackChunkName: "supplies" */ '@/views/Collect/supplies/PageView.vue'),
    meta: {
      title: "Abastecimento",
      backButton: ({ params }) => `/collect/condominiums/${params.condominium_id}/supplies`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  // Rota para acesso a partir da lista geral de abastecimentos, não exibindo
  // o condomínio na url

  {
    path: '/collect/supplies/view/:supply_id',
    name: 'collect-supplies-view',
    component: () => import(/* webpackChunkName: "supplies" */ '@/views/Collect/supplies/PageView.vue'),
    meta: {
      title: "Abastecimento",
      backButton: ({ params }) => `/collect/supplies`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },





]
