export default [

  { // Página com a listagem de condomínios
    path: '/collector',
    name: 'collector-index',
    component: () => import(/* webpackChunkName: "collector" */ '@/views/Collect/collector/PageIndex.vue'),
    meta: {
      title: "Coletor",
      KMenu: { icon: 'mdi-cellphone-dock', show: true, },
      roles: ['dev', "admin", 'manager', 'operator', 'collector']
    }
  },

  { // Página com a listagem de apartamentos de um condomínio
    path: '/collector/batch/:batch_id',
    name: 'collector-batch',
    component: () => import(/* webpackChunkName: "collector" */ '@/views/Collect/collector/PageList.vue'),
    meta: {
      title: "...",
      backButton: ({ params }) => `/collector`,
      KMenu: { parent: 'collect', show: false, },
      roles: ['dev', "admin", 'manager', 'operator', 'collector']
    }
  },

  // { // Página de registro da coleta
  //   path: '/collector/condominium/:condominium_id/collection/:collect_id',
  //   name: 'collect-collector-collect',
  //   component: () => import(/* webpackChunkName: "collector" */ '@/views/Collect/collector/PageCollect.vue'),
  //   meta: {
  //     title: "...",
  //     backButton: ({ params }) => `/collector/condominium/${params.condominium_id}`,
  //     KMenu: { parent: 'collect', show: false, },
  //     roles: ['dev', "admin", 'manager', 'operator', 'collector']
  //   }
  // },

]
