<template>
  <v-list-item :to="path">
    <v-list-item-icon>
      <v-icon>{{icon}}</v-icon>
    </v-list-item-icon>

    <v-list-item-title>{{title}}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "k-menu-without-children",
  props: {
    title: String,
    icon: String,
    path: String
  }
};
</script>

<style>
</style>