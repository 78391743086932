import { store } from '../store'
import { intersect } from '../plugins/utils'
import Bus from '../plugins/GlobalInteractions'



/**
 * 
 * - Verifica se a chave name está null. Se estiver null, é sinal que o acesso foi direto
 * pela url
 * 
 * 
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 */
export default async function authGuard(to, from, next) {

  // Roles da rota que está abrindo
  const toRoles = to.meta.roles

  // Permissões do usuário que está tentando acessar a rota
  const userRoles = store.getters['auth/roles'];

  // Está acessando a rota diretamente?
  const isDirectAccess = from.name === null;

  // Usuário logado?
  const isLoggedIn = store.getters['auth/isLoggedIn'];

  // Intersecção entre as permissões do usuário e as permissões requeridas da rota
  const intersectRoles = intersect(toRoles, userRoles);

  // Usuário tem permissão de acessar a rota desejada?
  const hasPermissionTo = intersectRoles && intersectRoles.length > 0


  // console.log('Roles:', store.getters['auth/roles']);
  // console.log('Routes:', routes);
  // console.log('authGuard', 'from:', from);
  // console.log('authGuard', 'to:', to);
  // console.log('Router', router);
  // console.log('Rota atual', router.currentRoute);
  // console.log('Interseção', intersectRoles);
  // console.log('Tem permissão de acessar?', hasPermissionTo);
  // console.log('Usuário logado?', store.getters['auth/isLoggedIn']);
  // console.log('é um acesso direto?', isDirectAccess);


  // Se o usuário pode acessar a rota, ou se a rota desejada possui guest.
  // Pois se o usuário estiver logado e e tentar acessar uma página guest, como
  // o site ou alguma pergunta frequente aberta, política de privacidade etc, 
  // se não puder acessar páginas guest ele seria redirecionado para o início
  if (hasPermissionTo || toRoles.includes('guest')) {
    // console.log('tem permissão');
    next()
  }

  // Se as permissões do usuário não dão direito de acessar a próxima rota
  else {

    // Está logado?
    if (isLoggedIn) {

      // É um acesso direto via URL
      if (isDirectAccess) {
        await next('/')
        Bus.message.error(`Você não pode acessar a página ${to.path}`)
      }

      // Se não for um acesso direto. Ou seja, ele veio de outra página
      else {
        // Se estiver na página de login, direcione para a página inicial
        // pois pode acontecer de o usuário estar em um redirecionamento
        // para uma página que ele não tem acesso. Nesse caso a navegação
        // seria cancelada antes de ir para a página desejada, ou seja, 
        // o usuário continuaria na página de login mesmo estando logado.
        if (from.name === 'Login') {
          Bus.message.show(`Redirecionado para a página inicial pois você não tem acesso a ${to.path}.`)
          next('/')
        } else {
          // console.log('não é acesso direto');
          Bus.message.error(`Você não pode acessar a página ${to.path}`)
          next(false)
        }
      }
    }

    // Se não estiver logado
    else {
      // Redireciona para o login e coloca na url a página desejada
      await next({ path: '/login', query: { next: to.path } })
      Bus.message.show(`Precisa estar logado para acessar esta página`)
    }
  }

}
