/***************************************************
 * UTILS
 * Funções soltas de cunho geral que servem em outros locais do app
 * 
 ****************************************************/


/*************************************************************************
 *
 * COMPARAÇÃO DE VALORES
 */


/*
 * Verifica se um valor é vazio, null, array vazio ou objeto vazio.
 * Não considera 0 como vazio.  
 */
export const empty = (value) => {
  if (value === 0 || value === false) return false;
  return (
    //null, '', undefined
    value !== 0 && value !== false && !value ||
    //É array E vazio
    isArray(value) && value.length === 0 ||
    //É um objeto vazio
    isObject(value) && Object.keys(value).length === 0
  )
}


/*************************************************************************
 * 
 * VERIFICAÇÃO DE TIPOS
 */


/*
 * Verifica se o valor passado é do tipo array
 */
export const isArray = (value) => {
  return Array.isArray(value)
}

/*
 * Verifica se o valor passado é do tipo objeto
 */
export const isObject = (value) => {
  return typeof value === 'object';
}

/*
 * Verifica se o valor passado é do tipo string
 */
export const isString = (value) => {
  return typeof value === 'string';
}

/*
 * Verifica se é um número inteiro.
 *
 * @examples
 *  isInteger("1") // true
 *  isInteger(1) // true
 *  isInteger(1.2) // false
 *  isInteger(1, true) // true
 *  isInteger("1", true) // false
 *
 * @param {Any} value: Valor que será comparado
 * @param {Boolean} forceType: Define se deve verificar o tipo, e não apenas o número
 */
export const isInteger = (value, forceType = false) => {
  if (forceType) return value === parseInt(value)
  return value == parseInt(value);
}

/*
 * Verifica se é do tipo float
 */
export const isFloat = (value) => {
  return value == +value && value != (value | 0) || isInteger(value);
}

/**
 * checkTypes
 * Verifica se o valor passado bate com algum dos tipos
 * http://tobyho.com/2011/01/28/checking-types-in-javascript/
 * 
 *    checkTypes( 1, String )
 *    -> false
 *    checkTypes( '1', String )
 *    -> true
 *    checkTypes( 1, Number )
 *    -> true
 *    checkTypes( '1', Number )
 *    -> false
 *    checkTypes( 1, [String, Number] )
 *    -> true
 *    checkTypes( 'a', [String, Number] )
 *    -> true
 *    checkTypes( {}, [String, Number] )
 *    -> false
 *    checkTypes( [], [String, Number] )
 *    -> false
 *    checkTypes( [], [String, Number, Array] )
 *    -> true
 *    checkTypes( {}, [String, Number, Array] )
 *    -> false
 *    checkTypes( {}, [String, Number, Array, Object] )
 *    -> true
 * 
 * 
 */
export const checkTypes = (value, types) => {
  // null ou undefined retornam false, independente do types
  if (value === null || value === undefined) return false;

  // Se types for array
  if (types.constructor === Array) {
    return types.filter(type => value.constructor === type).length > 0;
  }

  // Se types não for array
  return value.constructor === types;
}




/*************************************************************************
 * 
 * COMPARAÇÃO DE FORMATOS
 */


/*
 * Verifica se é um valor numérico
 * 
 * @param {Any} value: Valor a ser verificado
 */
export const isNumeric = (value) => {
  return Number(value) == value
}

/*
 * Verifica se é um e-mail
 */
export const isEmail = (value) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

/*
 * Verifica se é uma URL sem http
 */
export const isUrl = (value) => {
  var re = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return re.test(String(value).toLowerCase());
}

/*
 * Verifica se é uma data válida de acordo com o padrão do javascript
 */
export const isDate = (value, format) => {
  return (new Date(value)).getDay() >= 0
}

/**
 * Verifica se é uma data válida em portugues
 */
export const isDateBR = (value) => {
  var parts = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/.exec(value);

  return parts && Utils.isDate(parts[2] + '-' + parts[1] + '-' + parts[3]);
}

/*
 * Verifica se é uma hora válida. Não avalia segundos.
 */
export const isTime = (value, seconds) => {
  var regex = seconds ? /^([01][0-9]|2[0-3])\:([0-5][0-9])\:([0-5][0-9])$/ : /^([01][0-9]|2[0-3])\:([0-5][0-9])$/;
  if (!Utils.empty(value)) {
    return regex.test(value);
  }

  return false;
}

/*************************************************************************
 * 
 * CONVERSÃO DE FORMATOS
 */




/********************
 * BUSCA
 */

/**
 * Verifica 2 arrays e retorna os valores que estão em ambos
 * 
 * @param {Array} array1 
 * @param {Array} array2 
 * @return {Array} Array contendo os valores que estão nos 2 arrays
 */
export const intersect = (array1, array2) => {
  // return array1.filter(value => -1 !== array2.indexOf(value));
  return array1.filter(value => array2.includes(value))
}

/**
 * Retorna o valor de uma chave em um objeto de múltiplos níveis
 * 
 * @example
 * let obj = { a:'1',  b:{ c:10, d:2, e:{ f:'4', g:'5', h:{ i:'6' } } } }
 * objectPath( obj , 'b.e.h.i' ); // retorna '6'
 * 
 * @param  {Object} objObject
 * @param  {String} strAddress Endereço no formato 'chave.subchave.outrasubchave'
 * @return {Mixed}  Valor de acordo com o caminho
 */
export const objectPath = (objObject, strPath) => {
  const keys = strPath.split('.');
  let key;

  keys.forEach(key => {
    objObject = objObject[key];
  });

  return objObject;
}


/********************
 * FUNÇÕES DE APOIO
 */

/**
 * Agrupa um array de objetos por uma das chaves desse array.
 * 
 * @todo Mesclar com o objectPath() acima e permitir agurpamentos multidimensionais
 * com 
 * 
 * @exemplo
 * const list = [
 *  {"id":1,"name":"claudio","age":37,"city":"fortaleza"},
 *  {"id":2,"name":"isa","age":9,"city":"natal"},
 *  {"id":3,"name":"jose","age":37,"city":"fortaleza"},
 *  {"id":4,"name":"marta","age":42,"city":"afonso bezerra"},
 *  {"id":5,"name":"joelma","age":42,"city":"afonso bezerra"},
 *  {"id":6,"name":"jose","age":24,"city":"assu"}
 * ]
 * 
 * groupBy( list, 'name' )
 * 
 * {
 *  "claudio":[
 *    {"id":1,"name":"claudio","age":37,"city":"fortaleza"}
 *  ],
 *  "isa":[
 *    {"id":2,"name":"isa","age":9,"city":"natal"}
 *  ],
 *  "jose":[
 *    {"id":3,"name":"jose","age":37,"city":"fortaleza"},
 *    {"id":6,"name":"jose","age":24,"city":"assu"}
 *  ],
 *  "marta":[
 *    {"id":4,"name":"marta","age":42,"city":"afonso bezerra"}
 *  ],
 *  "joelma":[
 *    {"id":5,"name":"joelma","age":42,"city":"afonso bezerra"}
 *  ]
 * }
 * 
 * 
 * @param {Array} items Lista de objetos
 * @param {String} key Nome da chave que agrupará os demais dados
 */
export const groupBy = (items, key) => {
  // var result = {}
  // items.forEach(item => {
  //   if (result[item[key]]) result[item[key]].push(item)
  //   else result[item[key]] = [item]

  // })
  // return result;
  return items.reduce((accu, item) => {
    const keyValue = objectPath(item, key)
    // console.log(keyValue);

    if (accu[keyValue])
      accu[keyValue].push(item);
    else
      accu[keyValue] = [item];

    return accu;
  }, {})
}

/**
 * Ordena um array de objetos a partir de uma chave
 */
export const sort = (array, key) => {
  function compare(a, b) {
    if (String(a[key]).toLowerCase() < String(b[key]).toLowerCase())
      return -1;
    if (String(a[key]).toLowerCase() > String(b[key]).toLowerCase())
      return 1;
    return 0;
  }

  return array.sort(compare);
}

/**
 * Gerador de chaves aleatórias
 * Pelo menos 1 dos parâmetros numbers, lower e upper precisa ser true. 
 * Para que haja a máxima proteção contra conflitos, os três podem ser true
 * https://gist.github.com/6174/6062387#gistcomment-2742945
 * 
 * @param {Integer} length Tamanho gerado
 * @param {Boolean} numbers Define se existirão números 
 * @param {Boolean} lower Define se existirão letras minúsculas
 * @param {Boolean} upper Define se existirão letras maiúsculas
 */
export const keyGenerator = (length = 20, numbers = true, lower = true, upper = true) => {

  if (!numbers && !lower && !upper) throw new Error('Pelo menos UM dos 3 últimos parâmetros é obrigatório')

  let chars = [];
  if (numbers) chars.push(...[...'01234567890']);
  if (lower) chars.push(...[...'abcdefghjklmnopqrstuvwxyz']);
  if (upper) chars.push(...[...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']);

  return [...Array(length)].map(i => chars[Math.random() * chars.length | 0]).join``;
}

/**
 * Gerador de slug
  Create SLUG from a string
  This function rewrite the string prototype and also 
  replace latin and other special characters.
 
  Forked by Gabriel Fróes - https://gist.github.com/gabrielfroes
  Original Author: Mathew Byrne - https://gist.github.com/mathewbyrne/1280286
 */
export const slug = (string) => {

  return string.toLowerCase()
    .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')   // Special Characters #1
    .replace(/[èÈéÉêÊëË]+/g, 'e')       // Special Characters #2
    .replace(/[ìÌíÍîÎïÏ]+/g, 'i')       // Special Characters #3
    .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')    // Special Characters #4
    .replace(/[ùÙúÚûÛüÜ]+/g, 'u')       // Special Characters #5
    .replace(/[ýÝÿŸ]+/g, 'y')       		// Special Characters #6
    .replace(/[ñÑ]+/g, 'n')       			// Special Characters #7
    .replace(/[çÇ]+/g, 'c')       			// Special Characters #8
    .replace(/[ß]+/g, 'ss')       			// Special Characters #9
    .replace(/[Ææ]+/g, 'ae')       			// Special Characters #10
    .replace(/[Øøœ]+/g, 'oe')       		// Special Characters #11
    .replace(/[%]+/g, 'pct')       			// Special Characters #12
    .replace(/\s+/g, '-')           		// Replace spaces with -
    .replace(/[^\w\-]+/g, '')       		// Remove all non-word chars
    .replace(/\-\-+/g, '-')         		// Replace multiple - with single -
    .replace(/^-+/, '')             		// Trim - from start of text
    .replace(/-+$/, '');            		// Trim - from end of text
}

/**
    * Converte bytes em um tamanho legível.
    *
    * @example
    * filesize(12354353) // 11 mb
    *
    * @param {Number} bytes
    * @return {String} Valor convertido para kb, mb etc
    */
export const filesize = (bytes) => {
  if (!bytes) return;

  const map = [
    { min: 0, max: 1024, name: "bytes", divisor: 1 },
    { min: 1025, max: 1048576, name: "Kb", divisor: 1024 },
    { min: 1048577, max: 1073741824, name: "Mb", divisor: 1048576 },
    { min: 1073741825, max: 1099511627776, name: "Gb", divisor: 1073741824 }
  ];

  for (let item of map) {
    if (bytes >= item.min && bytes <= item.max) {
      return `${parseInt(bytes / item.divisor)} ${item.name}`;
    }
  }
}

/**
    * Exibe uma quantidade como string numérica
    *
    * @example
    * countString(0, 'mensagens', 'mensagem', 'nenhuma mensagem') // nenhuma mensagem
    * countString(0, 'mensagens', 'mensagem', null)               // 0 mensagem
    * countString(1, 'mensagens', 'mensagem', 'nenhuma mensagem') // 1 mensagem
    * countString(2, 'mensagens', 'mensagem', 'nenhuma mensagem') // 2 mensagens
    *
    * @param {Number} A quantidade
    * @param {String} Sufixo que será usado quando a quantidade for mais de um
    * @param {String} Sufixo que será usado quando a quantidade for 1
    * @param {String} Texto usado quando a quantidade for zero. Se estiver em branco, 
    *                 será usado o sufixo individual
    * 
    * @return {String} Texto contendo as verificações
    */
export const countString = (qty, suffixMany, suffixOne, textEmpty = null) => {
  qty = +qty

  if (qty === 0) return textEmpty || `0 ${suffixOne}`
  else if (qty === 1) return `1  ${suffixOne}`
  else return `${qty}  ${suffixMany}`
}

/**
 * Reescreve um objeto aplicando um prefixo definido às suas chaves
 * 
 * @example
 * const original = {
 *    name: 'ze',
 *    age: 23
 * }
 * 
 * prefixObjectKeys( original, 'people.*.' )
 * // -> {
 *    'people.*.name': 'ze',
 *    'people.*.age': 23,
 * }
 * 
 * 
 * @param {Object} obj Objecto original
 * @param {Object} obj Objecto original
 */
export const prefixObjectKeys = (obj, prefix = '') => {

  if (!prefix) return obj;

  const output = {}

  Object.keys(obj)
    .map(key => ({ key, prefixed: `${prefix}${key}` }))
    .forEach(item => output[item.prefixed] = obj[item.key])

  return output
}


/**
 * Verifica se o valor é uma latitude válida
 * 
 * @example
 * 
 * 
 * latitude:[
 *    rule('latitude') 
 * ]
 * 
 */
const isLatitude = (value) => {
  return isFloat(value) && value <= 90 && value >= -90
}

/**
 * Verifica se o valor é uma longitude válida
 * 
 * @example
 * 
 * 
 * longitude:[
 *    rule('longitude') 
 * ]
 * 
 */
const isLongitude = (value) => {
  return isFloat(value) && value <= 180 && value >= -180
}


export default {
  empty, isArray, isObject, isString, isInteger, isFloat,
  checkTypes, isNumeric, isEmail, isUrl,
  isDate, isDateBR, isTime, isLatitude, isLongitude,
  intersect, objectPath,
  sort, keyGenerator, slug, filesize, countString
};
