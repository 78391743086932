export default [
  {
    name: "tests",
    path: "",
    meta: {
      title: "Tests",
      KMenu: { icon: "mdi-test-tube", opened: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
  {
    path: "/tests/k-input-month",
    name: "tests-k-input-month",
    component: () =>
      import(
        /* webpackChunkName: "tests" */ "@/views/Tests/PageInputMonth.vue"
      ),
    meta: {
      title: "KInputMonth",
      KMenu: { parent: "tests", show: true },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
  {
    path: "/tests/k-input-masked",
    name: "tests-k-input-masked",
    component: () =>
      import(
        /* webpackChunkName: "tests" */ "@/views/Tests/PageInputMasked.vue"
      ),
    meta: {
      title: "KInputMasked",
      KMenu: { parent: "tests", show: true },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
  {
    path: "/tests/k-input-money",
    name: "tests-k-input-money",
    component: () =>
      import(
        /* webpackChunkName: "tests" */ "@/views/Tests/PageInputMoney.vue"
      ),
    meta: {
      title: "KInputMoney",
      KMenu: { parent: "tests", show: true },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
  {
    path: "/tests/k-label",
    name: "tests-k-label",
    component: () =>
      import(/* webpackChunkName: "tests" */ "@/views/Tests/PageKLabel.vue"),
    meta: {
      title: "KLabel",
      KMenu: { parent: "tests", show: true },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
  {
    path: "/tests/k-alert",
    name: "tests-k-alert",
    component: () =>
      import(/* webpackChunkName: "tests" */ "@/views/Tests/PageKAlert.vue"),
    meta: {
      title: "KAlert",
      KMenu: { parent: "tests", show: true },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
  {
    path: "/tests/address-select",
    name: "tests-address-select",
    component: () =>
      import(
        /* webpackChunkName: "tests" */ "@/views/Tests/PageAddressSelect.vue"
      ),
    meta: {
      title: "AddressSelect",
      KMenu: { parent: "tests", show: true },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
  {
    path: "/tests/person-autocomplete",
    name: "tests-person-autocomplete",
    component: () =>
      import(
        /* webpackChunkName: "tests" */ "@/views/Tests/PagePeopleAutocomplete.vue"
      ),
    meta: {
      title: "PeopleAutocomplete",
      KMenu: { parent: "tests", show: true },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
  {
    path: "/tests/action",
    name: "tests-action",
    component: () =>
      import(/* webpackChunkName: "tests" */ "@/views/Tests/PageKAction.vue"),
    meta: {
      title: "KAction",
      KMenu: { parent: "tests", show: true },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
  {
    path: "/tests/k-chip",
    name: "tests-k-chip",
    component: () =>
      import(/* webpackChunkName: "tests" */ "@/views/Tests/PageKChip.vue"),
    meta: {
      title: "KChip",
      KMenu: { parent: "tests", show: true },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
];
