import Bus from "./EventsBus";

export default {
  /**
   * Exibe um loading em tela cheia
   * this.$loading.show() // Exibe um loading com o texto padrão
   * this.$loading.show( 'Aguarde....' ) // Exibe um loading com um texto customizado
   * this.$loading.hide() // Esconde o loading
   */
  loading: {
    show: (text = "Aguarde. Carregando Dados") =>
      Bus.$emit("loading", true, text),
    hide: () => Bus.$emit("loading", false, null)
  },

  /**
   * 
   */
  app: {
    title: (title) => {
      Bus.$emit('app:title', title)
      document.title = `${title} - ${process.env.VUE_APP_NAME}`;
    }
  },

  /**
   * Exibe uma mensagem
   */
  message: {
    /**
     * Mostra uma mensagem padrão
     * this.$message.show('Mensagem padrão')
     * Mostra uma mensagem de um tipo específico
     * this.$message.show('Mensagem padrão', 'info')
     * Mostra uma mensagem com um timeout definido
     * this.$message.show('Mensagem padrão', null,  3000)
     */
    show: (message, type = "", timeout = 3000) =>
      Bus.$emit("message", type, message, timeout),
    /**
     * Mostra uma mensagem padrão
     * this.$message.success('mensagem de sucesso')
     * Mostra uma mensagem com um timeout definido
     * this.$message.success('mensagem de sucesso', 3000)
     */
    success: (message, timeout = 3000) =>
      Bus.$emit("message", "success", message, timeout),

    /**
     * Mostra uma mensagem de erro
     * this.$message.error('mensagem de erro')
     * Mostra uma mensagem com um timeout definido
     * this.$message.error('mensagem de erro', 3000)
     */
    error: (message, timeout = 3000) =>
      Bus.$emit("message", "error", message, timeout),

    /**
     * Mostra uma mensagem de erro a partir de um retorno de erro do servidor.
     * Esse retorno pode ser de erro de requisição, erro de validação, etc
     * A função precisa tratar os erros corretamente e exibir uma mensagem
     *
     * Se message não for informado, usar a mensagem retornada pela requisição
     *
     *
     * @example
     * // Passa o objeto de erro diretamente pro serverError se virar
     * try {
     *   const roles = ( await this.$api.get( '/roles' ) ).data.data
     * } catch( error ) {
     *   this.$messages.serverError(error)
     * }
     *
     */
    serverError: (errorObject, message = null, timeout = 3000) => {
      try {

        // Se o servidor estiver sem funcionar ou não responder
        // a requisição, não virá resposta no objeto de erro e 
        // cairá neste if
        if (!errorObject.response) {
          throw new Error('Verifique sua conexão e tente novamente mais tarde. Se o erro persistir, entre em contato com o suporte.')
        }

        // Dados do retorno do servidor
        const response = errorObject.response.data;

        // Cria uma mensagem padrão
        let message = 'Erro desconhecido'

        // Se existir a chave error no response é um erro interno do adonisjs
        // verificar se tem outras variações
        if (response.error && response.error.name) {

          // valor 'ExpiredJwtToken'. O usuário não permite fazer mais
          // nenhuma requisição que exija autenticação. Acho que eu
          // deveria levar o usuário direto para a página de login, mas
          // é possível que a pessoa esteja fazendo alguma ação e precisa
          // de tempo para salvar os dados e continuar após a operação
          if (response.error.name === "ExpiredJwtToken") {
            message = 'Login expirado. Faça login novamente para continuar.';
          }
          else if (response.error.name === 'Error') {
            message = 'Ocorreu um erro interno. Tente novamente mais tarde.'
          }
        }

        // Se existirem erros de validação
        // Estes são os erros de validação que possuem campos com erro. 
        // Neste caso, lá no final, os campos e os seus respectivos erros
        // serão retornados para que seja feito o tratamento, inserindo
        // as respectivas mensagens de erro nos campos. Estas mensagens
        // de erro serão adicionadas no estado do componente e todos os 
        // campos que serão validadados no servidor precisam possuir o
        // atributo :error-messages="errors.field_name", assim os campos
        // serão adicionados em errors e a mensagem de erro será exibida
        // abaixo deles
        else if (response.message === "ValidationError") {
          message = 'Erro de validação. Verifique os campos em vermelho';
        }

        // Se existirem erros de upload
        // Nem lembro mais como isso funciona. Depois eu vejo
        else if (response.message === "UploadValidationError") {
          // message = 'Erro no upload. Verifique o arquivo tente novamente';
          message = response.data.message || 'Erro no upload. Verifique o arquivo e tente novamente'
        }

        // Outros erros não definidos acima
        // Todos os outros erros de servidor que não estão bem especificados 
        // acima. Estes são erros do adonis e não estão no padrão normal
        // de retornos de erros do sistema
        else {
          // console.log('caiu no else', response)
          message = response.message
        }

        // Exibe a mensagem de erro
        Bus.$emit("message", "error", message, timeout);
        // Processa e dá o retorno
        return handleFieldValidationErrors(response)


      } catch (error) {
        // console.log('caiu no catch', error.message)
        message = error.message
        Bus.$emit("message", "error", 'Erro desconhecido: ' + message, timeout);
        return {};
      }
    }
  }
};

/**
 * Esta função pega o retorno de erro de validação do servidor, trata
 * os campos com erro e os devolve
 */
function handleFieldValidationErrors(response) {
  // 
  const items = response.data;
  // 
  const errors = {}

  // Adiciona os erros
  Array.isArray(items) &&
    items.forEach(item => {
      // console.log("erros messag: ", item, item.field);
      errors[item.field] = item.message;
    });

  return errors;

}
