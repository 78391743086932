<template>
  <v-form ref="k-form">
    <v-container grid-list-md>
      <v-layout row wrap>
        <slot></slot>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "k-form",
  data() {
    return {
      // Variáveis
    };
  },
  methods: {
    // Métodos
    validate() {
      return this.$refs["k-form"].validate();
    },

    reset() {
      return this.$refs["k-form"].reset();
    },

    resetValidation() {
      return this.$refs["k-form"].resetValidation();
    }
  }
};
</script>

<style scoped>
.progress-bar-hidden {
  opacity: 0;
}

.container {
  padding: 0;
}
</style>
