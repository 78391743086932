import Api from "@/plugins/Api";
const baseURL = 'settings'
export default {
  // Define que este será acessado via namespace
  namespaced: true,

  // Armazena o estado
  state: {
    // Armazena o usuário autenticado
    items: []
  },

  // Responsável por alterar o estado diretamente
  mutations: {
    update(stateObject, data) {
      stateObject.items = data;
    },
    clearState(stateObject) {
      stateObject.items = []

    }
  },

  // Executa upload, consultas a bancos de ddos, apis, etc.
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      // commit('clearState')
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }) {
      if (state.items.length === 0) {

        await dispatch('refresh')
      }


      return state.items;
    },

    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }) {
      let items = await Api.get(`/${baseURL}`);
      if (items.data.error === false) {
        items = items.data.data
      } else {
        items = []
      }
      commit("update", items);
    },

    /**
     * Atualiza um registro no store.
     * Se o registro existir, altere.
     * Se não existir, adicione.
     * Chama sort para adicionar os dados ordenados
     */
    async save({ commit, state, dispatch }, { payload, editing }) {
      try {

        let items = state.items;
        // Editando
        if (editing) {
          const { data: { data } } = await Api.put(`/${baseURL}/${payload.id}`, payload)
          items = items.map(item => {
            return item.id === data.id ? data : item
          })

          // Novo
        } else {
          const { data: { data } } = await Api.post(`/${baseURL}`, payload)
          items.push(data)
        }

        commit('update', items)
        // dispatch('updateSorted', items)
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza os partidos de forma ordenada pelo nome
     */
    updateSorted({ commit }, items) {
      //console.log('store/chamou sort')
      function compare(a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase())
          return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase())
          return 1;
        return 0;
      }

      commit('update', items.sort(compare));
    },

    /**
     * Remove o cadastro
     */
    async remove({ commit, state }, payload) {
      try {
        // Requisição para deletar
        const { data } = await Api.delete(`/${baseURL}/${payload.id}`)
        // Filtra para retirar do state
        const items = state.items.filter(item => item.id !== payload.id);
        // Dá o commit no state
        commit('update', items)
        // Retorna o resultado para a exibição das mensagens na página
        //console.log('retorno da api', data)
        return data;
      } catch (error) {
        //console.log('erro do store', error.response)
        throw error;
      }
    }

  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },

    values(state) {
      const values = {}
      state.items && state.items.forEach(item => values[item.name] = item.value)
      return values
    },


  }
};
