export default function(value) {
  if (typeof value != "object") {
    return value;
  }

  var obj = value;
  var newObj;

  if (obj instanceof Array) {
    newObj = [];
  } else if (!obj) {
    newObj = null;
  } else if (typeof obj == "object") {
    newObj = {};
  }

  for (let k in obj) {
    let v = obj[k];

    if (obj instanceof Array) {
      newObj.push(this.$clone(v));
    } else if (typeof obj === "object") {
      newObj[k] = this.$clone(v);
    } else {
      //should not arrive at this point
    }
  }

  return newObj;
}
