export default [



  {
    path: '/grouping',
    name: 'grouping',
    component: () => import(/* webpackChunkName: "grouping" */ '@/views/Grouping/PageIndex.vue'),
    meta: {
      title: "Grupos",
      KMenu: { icon: "mdi-google-circles-communities" },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/grouping/:group_id/:reference_module/:reference_module_id',
    name: 'group-children',
    component: () => import(/* webpackChunkName: "grouping" */ '@/views/Grouping/Children/PageView.vue'),
    meta: {
      title: "Grupo",
      backButton: true,
      KMenu: { parent: 'grouping', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator'],

    }
  },

]
