<template>
  <v-list-group :prepend-icon="icon" :value="opened">
    <template v-slot:activator>
      <v-list-item-title>{{title}}</v-list-item-title>
    </template>

    <template v-for="(child, key) in children">
      <k-menu-without-children :key="key" v-bind="child" />
    </template>

    <v-divider />
  </v-list-group>
</template>

<script>
import KMenuWithoutChildren from "./KMenuWithoutChildren";

export default {
  // Nome do componente
  name: "k-menu-with-children",
  components: { KMenuWithoutChildren },

  props: {
    // Lista de filhos
    children: Array,
    // Define o título do item
    title: String,
    // Define o path. Se for vazio, não tem link
    path: String,
    // Define o ícone
    icon: String,
    // Define se o menu começa aberto
    opened: { type: Boolean, default: false }
  }
};
</script>

<style>
</style>