import Vue from 'vue'
import Bus from '@/plugins/EventsBus'

/**
 * 
 * 
 */
const DEFAULT_TITLE = process.env.VUE_APP_NAME;

/**
 * 
 * 
 */
export function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}


/**
 * 
 * 
 */
export function afterEach(to) {
  Vue.nextTick(() => {
    // Remove a informação do título após a definição da rota
    Bus.$emit('app:title', null)

    // 
    document.title = `${to.meta.title} - ${DEFAULT_TITLE}` || DEFAULT_TITLE;
  });
}
