import Api from '@/plugins/Api'
import vuetify from '@/plugins/vuetify';

export default {
  // Define que este será acessado via namespace
  namespaced: true,

  // Armazena o estado
  state: {
    // Armazena o usuário autenticado e as suas permissões
    user: null,
    //
    token: null,
    // 
    roles: []
  },

  // Responsável por alterar o estado diretamente
  mutations: {
    changeUser(stateObject, data) {
      // console.log('dentro da mutation', data)
      stateObject.user = data;

      // const defaultColor = '#018074';
      // if (data.theme) {
      //   vuetify.framework.theme.themes.light.primary = data.theme.primary
      // } else {
      //   vuetify.framework.theme.themes.light.primary = defaultColor
      // }
      // console.log('mudar o vueti', )
    },

    mergeData(stateObject, data) {
      // console.log('merge data', data);
      // console.log('merged', { ...stateObject.user, ...data });

      stateObject.user = { ...stateObject.user, ...data };
    },

    changeToken(stateObject, token) {
      stateObject.token = token
    },

    changeRoles(stateObject, roles) {
      stateObject.roles = roles
    }
  },

  // Executa upload, consultas a bancos de ddos, apis, etc.
  actions: {

    /**
     * Passa a chave 'user' do objeto retornato pelo login do firebase
     */
    setUser({ commit, dispatch }, data) {
      // Se estiver logado, adicione a role 'logged', se não estiver, adicione 'guest'
      // if (data && data.roles) data.roles.push('logged')
      // else data.roles = ['guest'];
      // console.log('dentro do store', data)
      // console.log('perfi', data.roles.map(item => item.slug));

      // Chama a mutation changeUser
      commit('changeUser', data);

    },

    /**
     * setToken
     */
    setToken({ commit }, data) {
      commit('changeToken', data);
    },

    /**
     * setRoles
     */
    setRoles({ commit }, data) {
      commit('changeRoles', data);
    },

    /**
     * Gerencia o logout
     * Limpa os dados do usuário do store
     */
    logout({ commit }) {
      // console.log('chamado')
      const data = { user: null }
      commit('changeUser', data);
      commit('changeToken', null);
      commit('changeRoles', ['guest']);
    },

    /**
     * Pega os dados do profile
     */
    async profile({ commit }) {
      const { data: { data } } = await Api.get('/profile');
      commit('changeUser', data);
      commit('changeRoles', ['logged', ...data.roles.map(item => item.slug)])
    },

    /**
     * Salva alterações no profile
     */
    async updateProfile({ commit, state }, formData) {
      try {
        const { data: { data } } = await Api.put(`/profile`, formData);
        commit('changeUser', data);
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza as redes sociais do usuário logado
     */
    async updateSocialNetworks({ commit, state }, formData) {
      try {
        const { data: { data } } = await Api.put(`/profile/social_networks`, formData);
        console.log('data da api', data);

        commit('mergeData', data);
      } catch (error) {
        throw error;
      }
    },

    /**
     * 
     * Atualiza o tema do usuário logado
     */
    async updateTheme(store, { theme_id }) {
      try {
        // 
        const { data: { data } } = await Api.put('/profile/theme', { theme_id })
        store.commit('changeUser', data);

        return data;

      } catch (error) {
        throw error;
      }
    },


    /**
     * 
     * Atualiza o plano de governo
     */
    async updateGovernmentPlan(store, file) {
      try {
        // 
        // const { data: { data } } = await Api.put('/government_plan', { theme_id })

        const uploadURL = `/government_plan`;
        const { data: { data } } = await Api.upload(uploadURL, file, {
          fieldName: "file"
        });

        store.commit('changeUser', data);

        return data;

      } catch (error) {
        throw error;
      }
    },

    /**
     * Usuário altera sua própria senha
     * /profile/change_password
     */
    async userChangePassword(store, payload = {}) {
      try {
        const { current_password, password, password_confirmation } = payload;
        const { data: { data } } = await Api.put('/profile/change_password', { current_password, password, password_confirmation })

        return data;
      } catch (error) {
        throw error;
      }
    },



    /**
     * Atualiza a imagem do usuário logado
     */
    async updateAvatar(store, file) {
      try {
        // Realizando o upload
        const { data: { data } } = await Api.upload('/profile/avatar', file)

        store.commit('changeUser', data);

        return data;
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza a imagem de capa do usuário logado
     */
    async updateCover(store, picture) {
      try {
        // Realizando o upload
        const { data: { data } } = await Api.upload('/profile/cover', picture)

        // Acrescenta um valor à frente da url para que o navegador detecte a mudança
        // data.cover.url = `${data.cover.url}?t=${Date.now()}`

        store.commit('changeUser', data);
        return data;
      } catch (error) {
        throw error;
      }
    },




    /**
     * Verifica se o candidato logado tem alguma das permissões
     */
    hasRoles(state, required = []) {
      try {
        const intersection = state.roles.filter(role => required.includes(role))
        // console.log('intersection', intersection);

        return intersection.length >= 1;
      } catch (error) {
        return false;
      }
    }


  },

  // Retorna valores existentes no estado
  getters: {
    /**
     * Retorna os dados do usuário atual
     * @param {*} state 
     */
    user(state) {
      // if (  )
      return state.user;
    },

    /**
     * Retorna o token do usuário logado
     * @param {*} state 
     */
    token(state) {
      return state.token
    },

    /**
     * Retorna a lista de permissões do usuário atual
     * @param {R} state 
     */
    roles(state) {
      return state.roles
    },

    /**
     * Retorna se o usuário atual está ou não está logado
     * @param {*} state 
     */
    isLoggedIn(state) {
      return state.user !== null && state.user.user !== null;
    },

    /**
     * Retorna se o usuário logado é ou não um administrador
     * @param {*} state 
     */
    isAdmin(state) {
      if (state.user) {
        const required = ['dev', 'admin'];
        var intersection = state.roles.filter(role => required.includes(role))
        return intersection.length >= 1
      }

      return false;
    },




  }
}