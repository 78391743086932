import Api from "@/plugins/Api";

const baseURL = 'logs';

export default {
  // Define que este será acessado via namespace
  namespaced: true,

  // Armazena o estado
  state: {
    pagination: {},
    items: [],
  },

  // Responsável por alterar o estado diretamente
  mutations: {
    updateItems(stateObject, { total, perPage, lastPage, page, data }) {
      stateObject.pagination = { total, perPage, lastPage, page };
      stateObject.items = data;
    },
    clearState(stateObject) {
      stateObject.pagination = {}
      stateObject.items = []
    }
  },

  // Executa upload, consultas a bancos de ddos, apis, etc.
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * Força a atualização dos dados
     */
    // async refresh({ commit }, { page = 1, limit = 20, q = null, type = null }) {
    async refresh({ commit }, queryString) {


      const serialize = function (obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p) && obj[p]) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      }

      queryString = serialize(queryString)

      let items = await Api.get(`/${baseURL}?${queryString}`);
      if (items.data.error === false) {
        items = items.data.data
      } else {
        items = []
      }
      commit("updateItems", items);
    },

    /**
     * Atualiza um registro no store.
     * Se o registro existir, altere.
     * Se não existir, adicione.
     * Chama sort para adicionar os dados ordenados
     */
    async save({ commit, state, dispatch }, { formData, editing }) {
      try {

        let items = state.items;
        // Editando
        if (editing) {
          const { data: { data } } = await Api.put(`/${baseURL}/${formData.id}`, formData)
          items = items.map(item => {
            return item.id === formData.id ? formData : item
          })

          // Novo
        } else {
          const { data: { data } } = await Api.post(`/${baseURL}`, formData)
          items.push(data)
        }

        // dispatch('updateSorted', items)

        // Atualiza tudo de acordo com os dados de busca
        // console.log('searchData', searchData)
        // dispatch('refresh', searchData)

      } catch (error) {
        throw error;
      }
    },



    /**
     * Remove o cadastro
     */
    async remove({ commit, state }, formData) {
      try {
        //console.log('dentro do remoeve', formData)
        // Requisição para deletar
        const { data } = await Api.delete(`/${baseURL}/${formData.id}`)
        // Filtra para retirar do state
        const items = state.items.filter(item => item.id !== formData.id);
        // Dá o commit no state
        // commit('updateItems', items)
        // dispatch('refresh', searchData)
        // Retorna o resultado para a exibição das mensagens na página
        //console.log('retorno da api', data)
        return data;
      } catch (error) {
        //console.log('erro do store', error.response)
        return error.response;
      }
    }

  },

  // Retorna valores existentes no estado
  getters: {
    // all(state, a, b) {
    //   console.log('a:', a, 'b:', b)
    //   return state.items || [];
    // },
    pagination(state) {
      if (!state.pagination.total) return {
        total: 0, perPage: 20, page: 1, lastPage: 0
      }
      // console.log(state.items);

      // const { total, perPage, page, lastPage } = state.items
      return state.pagination
    },
    data(state) {
      if (state.items && !state.items.length) return []
      return state.items
    },

  }
};
