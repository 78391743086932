/*
  KMenu é um componente que gera o menu lateral a partir das definições de rotas.

  routes: [
    { 
      path: '/', 
      name: 'Map', 
      component: Maps, 
      meta{
        title: 'Mapa de Empresas',,
        KMenu: {  } 
      }
      
    },
    { 
      path: '/map/coords-by-address', 
      name: 'CoordsByAddress', 
      component: CoordsByAddress, 
      meta{
        title: 'Coordenadas por Endereço',
        KMenu: {  icon: 'mdi-close', parent: 'Map' }, 
      }
      
    },
    { 
      path: '/map/coords-by-multiple-addresses', 
      name: 'CoordsByMultipleAdresses', 
      component: CoordsByMultipleAdresses, 
      meta{
        title: 'Coordenadas em Lote',
        KMenu: {  parent: 'Map' } 
      }
      
    },
    { 
      path: '/map/park-coords', 
      name: 'ParkCoords', 
      component: ParkCoords, 
      meta{
        title: 'Coordenadas do Parque',
        KMenu: { icon: 'mdi-map' } 
      }
      
    },
  ]

  O componente pegará os dados definidos nas rotas para gerar o menu. As opções originais
  path e name serão usadas na geração e uma nova opção KMenu será adicionada, onde podemos
  definir titulo da página, ícone e um pai. No caso do pai, a linha específica será adicionada
  dentro do array children da rota pai fazendo com que sejam gerados submenus


*/
<template>
  <div>
    <!-- SE estiver sendo exibida em uma tela XS ou SM -->
    <!-- <menu-item v-for="(menuItem, key) in menuItems" :key="'menu-item-'+key" v-bind="menuItem"></menu-item>
    -->
    <v-list expand>
      <template v-for="(menuItem, key) in menuItems">
        <!-- Se não tiver filhos -->
        <template v-if="menuItem.children && menuItem.children.length > 0">
          <k-menu-with-children v-bind="menuItem" :key="'menu-item-' + key" />
        </template>

        <!-- Se tiver filhos -->
        <template v-else>
          <k-menu-without-children v-bind="menuItem" :key="'menu-item-' + key" />
        </template>
      </template>
    </v-list>
  </div>
</template>

<script>
import Vue from "vue";
import KMenuWithChildren from "./KMenuWithChildren";
import KMenuWithoutChildren from "./KMenuWithoutChildren";

export default {
  name: "k-menu",

  components: { KMenuWithChildren, KMenuWithoutChildren },

  data() {
    return {
      // Variáveis
    };
  },

  computed: {
    menuItems() {
      // Pega as permissões do usuário atual
      // let currentUserRoles = Api.getCurrentRoles();

      const currentUserRoles = this.$store.getters["auth/roles"];
      // console.log("currentUserROles", currentUserRoles);

      const items = [];
      const routes = this.$router.options.routes;

      routes.forEach((row, i) => {
        if (row.meta.KMenu.show !== false) {
          const intersection = row.meta.roles.filter(
            value => -1 !== currentUserRoles.indexOf(value)
          );
          // console.log('rota', row,'interseçã', intersection);
          if (intersection.length) {
            const item = {
              path: row.path !== "" && row.path !== null ? row.path : null,
              icon: row.meta.KMenu.icon ? row.meta.KMenu.icon : null,
              title: row.meta.title ? row.meta.title : null,
              opened: row.meta.KMenu.opened ? row.meta.KMenu.opened : false,
              show: row.meta.KMenu.show ? row.meta.KMenu.show : true,
              children: []
            };

            if (row.meta.KMenu.parent) {
              // Pesquisa nas rotas, alguma entrada que tenha tenha o nome igual ao parent do atual
              const idx = routes.findIndex(
                value => value.name === row.meta.KMenu.parent
              );
              // Adiciona o item ao pai
              items[idx].children.push(item);
            } else {
              // Adiciono o item na posição i
              Vue.set(items, i, item);
            }
          }
        }
      });

      // Retorno a lista de itens filtrada para eliminar os undefined
      return items.filter(item => item != undefined);
    }
  },

  methods: {
    // Métodos
  },

  created() {}
};
</script>

<style scoped>
</style>
