/*
 * Validação como mixins
 *
 * ---------------------------
 * OBSERVAÇÕES
 * ---------------------------
 * Dentro dos mixins, 'this' é relativo ao contexto do componente
 */
const PersonalIDs = {
  isValidCPF(value) {
    value = value.replace(/[^\d]+/g, "");
    let sum;
    let rest;

    sum = 0;

    if (value == "00000000000") {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
    }
    rest = (sum * 10) % 11;

    if (rest == 10 || rest == 11) {
      rest = 0;
    }

    if (rest != parseInt(value.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let j = 1; j <= 10; j++) {
      sum = sum + parseInt(value.substring(j - 1, j)) * (12 - j);
    }

    rest = (sum * 10) % 11;

    if (rest == 10 || rest == 11) {
      rest = 0;
    }

    if (rest != parseInt(value.substring(10, 11))) {
      return false;
    }

    return true;
  },

  isValidCNPJ(value) {
    var cnpj = value.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  },
  mod11(number) {
    const parts = [...number.toString()];
    let sum = 0;
    let mult = 2;
    // console.log(parts);

    parts.forEach((current, i) => {
      // sum += parts[ parts.length-i-1 ] * mult
      sum += current * mult;
      // console.log(parts[ parts.length-i-1 ], mult, parts[ parts.length-i-1 ] * mult,  sum);
      mult++;
    });

    resto = sum % 11;
    //console.log("resto", resto, "dv", 11 - resto);

    return 11 - resto;
  },

  mod10(number) {
    const parts = [...number.toString()];
    let sum = 0;
    //console.log(parts);

    parts.forEach((current, i) => {
      // if ( i=== 0 ) sum = current * 2;
      const inverso = parts[parts.length - i - 1];
      // console.log('sum', sum, 'cur',  current, 'multiplicador',i % 2 ? 1 : 2, 'resultado', current*(i % 2 ? 1 : 2));
      //console.log(inverso, "*", i % 2 ? 1 : 2, "=", inverso * (i % 2 ? 1 : 2));
      // console.log( 'current', current, 'inverso', inverso );

      // return parseInt(sum) + parseInt(current) * ( i % 2 ? 1 : 2 )
      const multiplicacao = inverso * (i % 2 ? 1 : 2);
      // soma os algarismos
      const somaDosAlgarismos = [...multiplicacao.toString()].reduce(
        (sum, cur) => parseInt(sum) + parseInt(cur)
      );
      //console.log(somaDosAlgarismos);
      sum += parseInt(somaDosAlgarismos);
    });

    const resto = sum % 10;

    const mod10 = resto === 0 ? 0 : 10 - resto;
    //console.log("rsto", sum % 10, "dv", mod10);

    return mod10;
  }

  /**
   * Retorna o(s) numDig Dígitos de Controle Módulo 11 do
   * dado, limitando o Valor de Multiplicação em limMult,
   * multiplicando a soma por 10, se indicado:
   *
   *    Números Comuns:   numDig:   limMult:   x10:
   *      CPF                2         12      true
   *      CNPJ               2          9      true
   *      PIS,C/C,Age        1          9      true
   *      RG SSP-SP          1          9      false
   *
   * @param  {String}  dado    String dado contendo o número (sem o DV)
   * @param  {Integer} numDig  Número de dígitos a calcular
   * @param  {Integer} limMult Limite de multiplicação
   * @param  {Boolean} x10     Se true multiplica soma por 10
   * @return {String}          Dígitos calculados
   */
  // mod11(dado, numDig, limMult, x10){
  // var mult, soma, i, n, dig;

  //   if(!x10) numDig = 1;
  //   for(n=1; n<=numDig; n++){
  //     soma = 0;
  //     mult = 2;
  //     for(i=dado.length-1; i>=0; i--){
  //       soma += (mult * parseInt(dado.charAt(i)));
  //       if(++mult > limMult) mult = 2;
  //     }
  //     if(x10){
  //       dig = ((soma * 10) % 11) % 10;
  //     } else {
  //       dig = soma % 11;
  //       if(dig == 10) dig = "X";
  //     }
  //     dado += (dig);
  //   }
  //   return dado.substr(dado.length-numDig, numDig);
  // },
};

export default PersonalIDs;
// module.exports = PersonalIDs
