import Vue from "vue";
import Vuex from "vuex";

// Modules
import auth from "./modules/auth";
import dashboard from "./modules/dashboard";
import logs from "./modules/logs";
import messages from "./modules/messages";
import password_recovery from "./modules/password_recovery";
// import polls from "./modules/polls";
import roles from "./modules/roles";
import support_categories from "./modules/support_categories";
import support from "./modules/support";

// MIGRADOS

// Constructions
import constructions from "@/views/Constructions/_store/constructions";
import construction_statuses from "@/views/Constructions/_store/construction_statuses";
// 
import groups from '@/views/Grouping/store'
import settings from '@/views/Settings/store'
// Collect
import expiration_dates from '@/views/Collect/_store/expiration_dates'
import measurement_units from '@/views/Collect/_store/measurement_units'
import products from '@/views/Collect/_store/products'
import condominiums from '@/views/Collect/_store/condominiums'
import consumption_contracts from '@/views/Collect/_store/consumption_contracts'
import supplies from '@/views/Collect/_store/supplies'
import collect_dashboard from '@/views/Collect/_store/dashboard'
import collections from '@/views/Collect/_store/collections'
// 
import people from "@/views/People/_store";
import contact_types from "@/views/ContactTypes/_store";
// FAQs
import faqs from "@/views/Faqs/_store";
import faq_categories from "@/views/Faqs/_store/categories";
// Financeiro
import financials from "@/views/Finance/_store/financials";
import financial_statuses from "@/views/Finance/_store/financial_statuses";
import financial_categories from "@/views/Finance/_store/financial_categories";
import financial_methods from "@/views/Finance/_store/financial_methods";
import financial_gateways from "@/views/Finance/_store/financial_gateways";
import financial_connections from "@/views/Finance/_store/financial_connections";


Vue.use(Vuex);

const modules = {
  auth,
  constructions,
  construction_statuses,
  dashboard,
  faq_categories,
  faqs,
  logs,
  messages,
  support_categories,
  support,
  password_recovery,
  // polls,
  roles,
  contact_types,
  people,
  // Dentro da sua própria pasta
  groups,
  settings,
  // Collect
  expiration_dates,
  measurement_units,
  products,
  condominiums,
  consumption_contracts,
  supplies,
  collections,
  collect_dashboard,
  // Financeiro
  financials,
  financial_statuses,
  financial_categories,
  financial_methods,
  financial_gateways,
  financial_connections,
}


export const store = new Vuex.Store({
  // Armazena os modulos da aplicação
  modules,

  actions: {
    clearAllState({ commit, dispatch }) {
      // Loop pelo estado
      Object.keys(modules)
        .filter(moduleName => moduleName !== 'auth')
        .forEach(moduleName => {
          dispatch(`${moduleName}/clear`)
        })

    }
  }
});

