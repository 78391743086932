<template>
  <v-chip v-if="!isProduction" :class="environmentClass">
    <b class="text-truncate">{{ environment }}</b>
  </v-chip>
</template>

<script>
export default {
  computed: {
    isProduction() {
      return this.environment === "production";
    },
    environment() {
      return this.$api.environment;
    },
    /*
     * Classe do environment
     */
    environmentClass() {
      return ["local", "staging"].includes(this.environment)
        ? `chip-${this.environment}`
        : null;
    }
  }
};
</script>

<style scoped>
.chip-local {
  background: rgba(0, 0, 0, 0.05) !important;
}
.chip-staging {
  background: rgba(255, 0, 0, 0.5) !important;
  color: white !important;
}
</style>
