

export default [


  {
    path: '/people',
    name: 'admin-users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/People/list/PageIndex.vue'),
    meta: {
      title: "Pessoas",
      KMenu: { icon: "mdi-account" },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/people/add',
    name: 'admin-users-add',
    component: () => import(/* webpackChunkName: "users" */ '@/views/People/PageView.vue'),
    meta: {
      title: "Cadastro de Pessoas",
      // backButton: '/people',
      KMenu: { parent: 'admin-users', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator'],

    }
  },
  {
    path: '/people/view/:id',
    name: 'admin-users-edit',
    component: () => import(/* webpackChunkName: "users" */ '@/views/People/PageView.vue'),
    meta: {
      title: "Editar",
      // backButton: '/people',
      KMenu: { parent: 'admin-users', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator'],

    }
  },
]
