export default [



  {
    path: '/collect/condominiums/:condominium_id/consumption_contracts',
    name: 'collect-condominiums-view',
    component: () => import(/* webpackChunkName: "consumption_contracts" */ '@/views/Collect/consumption_contracts/PageIndex.vue'),
    meta: {
      title: "Contratos de Consumo",
      backButton: ({ params }) => `/collect/condominiums/view/${params.condominium_id}`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/collect/condominiums/:condominium_id/consumption_contracts/add',
    name: 'collect-consumption_contracts-add',
    component: () => import(/* webpackChunkName: "consumption_contracts" */ '@/views/Collect/consumption_contracts/PageView.vue'),
    meta: {
      title: "Novo Contrato de Consumo",
      backButton: ({ params }) => `/collect/condominiums/${params.condominium_id}/consumption_contracts`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/collect/condominiums/:condominium_id/consumption_contracts/view/:consumption_contract_id',
    name: 'collect-consumption_contracts-view',
    component: () => import(/* webpackChunkName: "consumption_contracts" */ '@/views/Collect/consumption_contracts/PageView.vue'),
    meta: {
      title: "Contrato de Consumo",
      backButton: ({ params }) => `/collect/condominiums/${params.condominium_id}/consumption_contracts`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },


  {
    path: '/collect/condominiums/:condominium_id/consumption_contracts/:consumption_contract_id/collections',
    name: 'collect-consumption_contracts-collections',
    component: () => import(/* webpackChunkName: "consumption_contracts" */ '@/views/Collect/consumption_contracts/PageCollections.vue'),
    meta: {
      title: "Coletas",
      backButton: ({ params }) => `/collect/condominiums/${params.condominium_id}/consumption_contracts/view/${params.consumption_contract_id}`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },


]
