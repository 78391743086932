import Api from "@/plugins/Api";
import Vue from 'vue'

const baseURL = 'help/support';


export default {
  // Define que este será acessado via namespace
  namespaced: true,

  // Armazena o estado
  state: {
    // Armazena o usuário autenticado
    items: [],
    currentThread: {}
  },

  // Responsável por alterar o estado diretamente
  mutations: {
    // Envia a primeira mensagem
    send(stateObject, data) {
      stateObject.currentThread = data;
    },
    // Carrega todas as mensagens de uma thread
    view(stateObject, data) {
      stateObject.currentThread = data;
      const index = stateObject.items.findIndex(item => item.id === data.id);

      // Caso alguma mensagem seja definida. Pois o view às vezes é mandado
      // vazio para que nenhuma mensagem seja exibida enquanto a outra ainda é carregada.
      if (index !== -1) {
        Vue.set(stateObject.items, index, {
          ...stateObject.items[index],
          parent_unread: 0,
          children_unread: 0
        })
      }
    },
    // Responde a mensagem
    reply(stateObject, data) {
      stateObject.currentThread.replies.push(data)
    },
    // Carrega a lisat de mensagens
    updateData(stateObject, data) {
      stateObject.items = data;
    },
    addData(stateObject, data) {
      stateObject.items = [data, ...stateObject.items]
    },
    close(stateObject, data) {
      Vue.set(stateObject.currentThread, 'closed_at', data.closed_at)

      const index = stateObject.items.findIndex(item => item.id === data.id);

      // Caso alguma mensagem seja definida. Pois o view às vezes é mandado
      // vazio para que nenhuma mensagem seja exibida enquanto a outra ainda é carregada.
      if (index !== -1) {
        Vue.set(stateObject.items, index, {
          ...stateObject.items[index],
          is_closed: 1
        })
      }



    },
    // Limpa a thread atual
    clearCurrentThread(stateObject) {
      stateObject.currentThread = {}
    },
    // Limpa tudo
    clearState(stateObject) {
      stateObject.items = []
      stateObject.currentThread = {}
    }
  },

  // Executa upload, consultas a bancos de ddos, apis, etc.
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * 
     */
    async all({ commit }, payload) {
      try {
        // console.log('test', payload);
        // commit('updateData', [])
        const { data: { data } } = await Api.get(`/${baseURL}/v2`, payload);
        commit('updateData', data)
      } catch (error) {
        console.log('err', error)
        throw error;
      }
    },


    /**
     * 
     */
    async markAllAsRead({ commit }) {
      try {
        const { data: { data } } = await Api.post(`/${baseURL}/mark-all-as-read`);
      } catch (error) {
        throw error;
      }
    },

    /**
     * Envia uma mensagem
     */
    async send({ commit }, payload) {
      try {
        const { data: { data } } = await Api.post(`/${baseURL}/send`, payload);
        // commit('send', data)
        commit('addData', data)
        return data;

      } catch (error) {
        console.log('err', error)
        throw error;
      }
    },

    /**
     * Marca uma mensagem como encerrada
     */
    async close({ commit }, id) {
      try {
        // console.log('entrou no close', '');
        if (confirm("Tem certeza que deseja fechar este tópico?")) {
          const { data: { data } } = await Api.put(`/${baseURL}/close/${id}`);
          // console.log('close', data)
          commit('close', data)
          return data;
        }

      } catch (error) {
        console.log('err', error)
        throw error;
      }
    },

    /**
     * Responde uma mensagem
     */
    async reply({ commit }, { id, text }) {
      try {

        const { data: { data } } = await Api.post(`/${baseURL}/reply/${id}`, { text });

        commit('reply', data)
      } catch (error) {
        console.log('err', error)
        throw error;
      }
    },

    /**
     * Carrega a thread
     */
    async view({ commit }, id) {
      try {
        commit('view', {})
        const { data: { data } } = await Api.get(`/${baseURL}/read/${id}`);
        // console.log('carrega a thread', data);

        commit('view', data)

      } catch (error) {
        console.log('err', error)
        console.log('err', error)
        throw error;
      }
    },

    /**
      * Atualiza um registro no store.
      * Se o registro existir, altere.
      * Se não existir, adicione.
      * Chama sort para adicionar os dados ordenados
      */
    async save({ commit, state, dispatch }, { payload, editing }) {
      try {

        let items = state.items;
        // Editando
        if (editing) {
          // 
          const { data: { data } } = await Api.put(`/admin/${baseURL}/${payload.id}`, payload);
          // 
          items = items.map(item => {
            return item.id === payload.id ? data : item
          })

          // Novo
        } else {
          const { data: { data } } = await Api.post(`/admin/${baseURL}`, payload);
          items.push(data)
        }

        dispatch('updateSorted', items)
      } catch (error) {
        console.log('err', error)
        throw error;
      }
    },

    /**
     * Limpa a thread atual
     */
    clearCurrentThread({ commit }) {
      commit('clearCurrentThread')
    },


    /**
     * Faz o upload de imagem ou documento
     */
    async upload({ commit, state }, { file, ...payload }) {
      try {

        const uploadURL = `/${baseURL}/upload/${payload.id}`
        const { data: { data } } = await Api.upload(uploadURL, file.file, {
          fieldName: "file", method: 'post', data: payload
        });

        commit('reply', data);
        return data;


      } catch (error) {
        console.log('err', error)
        throw error
      }
    },


    /**
     * Atualiza os partidos de forma ordenada pelo nome
     */
    updateSorted({ commit }, items) {
      //console.log('store/chamou sort')
      function compare(a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase())
          return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase())
          return 1;
        return 0;
      }

      commit('updateData', items.sort(compare));
    },


    /**
     * Remove o cadastro
     */
    async remove({ commit, state }, formData) {
      try {
        const { data } = await Api.delete(`/admin/${baseURL}/${formData.id}`);
        return data;
      } catch (error) {
        console.log('err', error)
        throw error;
      }
    }

  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },
    currentThread(state) {
      return state.currentThread || [];
    },
    statuses() {
      return [
        { value: '', text: 'Filtrar', disabled: true },
        { value: null, text: 'Todas', disabled: false },
        { value: 1, text: 'Abertas', disabled: false },
        { value: -1, text: 'Fechadas', disabled: false },
      ]
    },

    // Quantidade de mensagens não lidas
    unread(state) {
      if (state.items && !state.items.length) return 0

      const length = state.items
        .map(item => item.parent_unread + item.children_unread)
        .reduce((curr, prev) => curr + prev, 0)

      return length
    }
  }
};
