/*
  Configuração do meta

  {
    # Nome para a rota. Serve como referência em outros locais, até mesmo para 
    # navegar usando somente o nome
    name: 'news',

    # Caminho para onde esta rota será chamada
    path: '/news',

    # São parÂmetros usados para definir uma rota. Será o valor padrão caso não seja
    # informado no redirecionamento
    params: { id: 1 }

    # Configurações básicas de uma rota. Qualquer valor aqui dentro será passado
    # para ela e será acessível de dentro dos componentes
    meta: {
      # Título que aparece no topo da página e no menu
      title: "Política de Privacidade",

      # Exibe um botão de voltar no lugar do botão do menu
      # Valores possíveis:

        true:      Informa que o botão deve retornar para a página anterior. Deve ser
                   Usado quando não temos certeza qual a página que ele deve voltar.

        '/login': Informa para o botão que ele deve navegar para uma página específica
                   independentemente da página anterior.

        '(param ) => `/users/edit/${param.user_id}` : 
                  É uma função que recebe os parâmetros da url da rota atual
                  como parâmetro dela mesmo e permite a criação de uma url
                  personalizada e correta, não dependendo do botão de voltar
      backButton: true,

      # Exibe o rodapé
      # Se o valor for false, não exibe. O padrão é true.
      # Pode ser usado em páginas que possuem componentes que precisam usar a tela inteira,
      # como um componente de chat, onde o rodapé já seria utilizado pelo campo de texto
      footer: false,

      # KMenu
      # Configura o menu
      KMenu: { 
        # Define que o menu pode não não pode ser exibido. Valor padrão: true
        # Esta opção é usada quando temos parâmetros na url e que eles não 
        # existirão no escopo do menu, como a edição de um cadastro ou a visualização 
        # de uma notícia. Também utilizado quando a página é acessível de outros locais
        # e não tem necessidade de ser mostrada no menu lateral, como a Política
        # de Privacidade
        show: false,

        # Define um ícone para ser usado no menu lateral
        icon: 'mdi-account',

        # Serve para criar itens aninhados dentro de outro no menu. Neste exemplo,
        # este item seria criado dentro do menu cujo nome é "news". Os itens que
        # possuem filhos não devem receber o "path" explicado no início.
        parent: 'news',

        # Serve para dizer que o item ficará fechado. Usado quando se tratar de um 
        # que possui filhos. Caso não possua filhos, é ignorado
        opened: false,
      
      },
      
      
      # Define quais os níveis de usuário que podem acessar o componente
      roles: ["guest", 'logged']
    }
  },








*/


import Login from '@/views/Auth/Login.vue'

// Importação das rotas de outros controllers
import routesTest from '@/views/Tests/_routes'
import routesCollect from '@/views/Collect/_routes'
import routesGrouping from '@/views/Grouping/_routes'
import routesFinance from '@/views/Finance/_routes'
import routesLogs from '@/views/Logs/_routes'
import routesConstructions from '@/views/Constructions/_routes'
import routesPeople from '@/views/People/_routes'
import { routesHelp, routesAdminHelp } from '@/views/Faqs/_routes'


const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: "Login",
      KMenu: {}, //icon: 'mdi-key', opened: true, parent:'nome-do-component-pai'
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["guest"]
    }

  },
  {
    path: '/auth/recovery',
    name: 'home',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/PasswordRecovery.vue'),
    meta: {
      title: "Esqueci minha senha",
      KMenu: { icon: "mdi-home-variant-outline" },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["guest"]
    }
  },

  // Início
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home/Index.vue'),
    meta: {
      title: "Início",
      KMenu: { icon: "mdi-home-variant-outline" },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["logged"]
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile/PageIndex.vue'),
    meta: {
      title: "Meus Dados",
      KMenu: { icon: null, show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["logged"]
    }
  },

  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  OBRAS
  //  Administração dos dados das obras
  //
  // ///////////////////////////////////////////////////////////////////////////////

  ...routesConstructions,

  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  MEDIÇÃO INDIVIDUALIZADA
  //  Administração dos dados das coletas
  //
  // ///////////////////////////////////////////////////////////////////////////////

  ...routesCollect,

  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  FINANCEIRO
  //  Administração das entradas e saídas do sistema
  //
  // ///////////////////////////////////////////////////////////////////////////////

  ...routesFinance,

  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  PESSOAS
  //
  // ///////////////////////////////////////////////////////////////////////////////

  ...routesPeople,

  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  GRUPOS
  //  Administração dos dados das obras
  //
  // ///////////////////////////////////////////////////////////////////////////////

  ...routesGrouping,

  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  LOGS
  //  Administra logs do sistema
  //
  // ///////////////////////////////////////////////////////////////////////////////

  ...routesLogs,

  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  HELP
  //  Itens de ajuda
  //
  // ///////////////////////////////////////////////////////////////////////////////

  ...routesHelp,



  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  CONFIGURAÇÕES
  //  Tabelas e configurações gerais do sistema
  //
  // ///////////////////////////////////////////////////////////////////////////////

  {
    name: 'settings',
    path: '',
    meta: {
      title: "Configurações",
      KMenu: { icon: "mdi-cog-outline", opened: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },




  // ///////////////////////////////////////////////////////////////////////////////
  //
  //  HELP
  //  Itens de ajuda
  //
  // ///////////////////////////////////////////////////////////////////////////////

  ...routesAdminHelp,




  /**
   * Configurações do Sistema
   */
  {
    path: '/settings/settings',
    name: 'settings-settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/PageIndex'),
    meta: {
      title: "Configurações do Sistema",
      KMenu: { parent: 'settings' },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', 'admin']
    }
  },



  {
    path: '/measure',
    name: 'collect-measure',
    component: () => import(/* webpackChunkName: "measure_units" */ '@/views/Collect/measure_units/PageIndex.vue'),
    meta: {
      title: "Unidades de Medida",
      KMenu: { parent: 'settings' },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },
  {
    path: '/products',
    name: 'collect-products',
    component: () => import(/* webpackChunkName: "products" */ '@/views/Collect/products/PageIndex.vue'),
    meta: {
      title: "Produtos",
      KMenu: { parent: 'settings' },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/expiration_dates',
    name: 'collect-expiration_dates',
    component: () => import(/* webpackChunkName: "expiration_dates" */ '@/views/Collect/expiration_dates/PageIndex.vue'),
    meta: {
      title: "Datas de Vencimento",
      KMenu: { parent: 'settings' },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  /**
   * Tipos de Contatos
   */
  {
    path: '/settings/contact_types',
    name: 'settings-contact_types',
    component: () => import(/* webpackChunkName: "contact_types" */ '@/views/ContactTypes/PageIndex'),
    meta: {
      title: "Tipos de Contatos",
      KMenu: { parent: 'settings' },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev']
    }
  },





  //////////////////
  //
  // TESTS
  //
  ///////////////////
  ...routesTest,












  // ////////////////////////////////////////////////////////
  //
  // PÁGINAS EXTERNAS
  //
  // ////////////////////////////////////////////////////////
  {
    path: '/privacidade',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Pages/Privacy.vue'),
    meta: {
      title: "Política de Privacidade",
      backButton: true, // true volta para a anterior. /faqs volta para a página faqs
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["guest", 'logged']
    }
  },






  // ////////////////////////////////////////////////////////
  //
  // ERROS
  // Precisa ser a última rota
  //
  // ////////////////////////////////////////////////////////

  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Errors/Index.vue'),
    params: { code: 404 },
    meta: {
      title: "Erro",
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["logged"]
    }
  },
]

export default routes;
