import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import "@/assets/css/main.css";

///////////////////////////
// Error handler
// import { initErrorHandler } from "./plugins/ErrorService";
// initErrorHandler(Vue);

//////////////////////
// Vue clone
import vueClone from "./plugins/clone";
Vue.prototype.$clone = vueClone;

///////////////////////////
// EventBus
// Cria funções globais que ativam eventos em locais específicos
//
import Bus from "./plugins/GlobalInteractions";
Vue.prototype.$loading = Bus.loading;
Vue.prototype.$message = Bus.message;
Vue.prototype.$app = Bus.app;

// ////////////////////////////////////////////////////////////////////////////
// Validação
// Mantém a validação global para todos os componentes
const validation = require("./plugins/validation");
Vue.prototype.$validation = validation.default;

// ////////////////////////////////////////////////////////////////////////////
// API
// Mantém a biblioteca de requisições disponível para toda a aplicação
const Api = require("./plugins/Api");
Vue.prototype.$api = Api.default;

// ///////////////////////////////////////////////////////////////////////////
// Filters
import "@/filters";

Vue.config.productionTip = false;

// Criando um atraso no carregamento do Vue
Api.default.validateToken().then((user) => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
