var stringToHex = function (str) {
  /**
   * Alpha
   */
  let alpha = 100;

  /**
   * Cria um inteiro baseado na string
   */
  function hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  /**
   * Cria hexadecimal baseado no inteiro criado em hashcode
   */
  function intToARGB(i) {
    var hex = ((i >> 24) & 0xFF).toString(16) +
      ((i >> 16) & 0xFF).toString(16) +
      ((i >> 8) & 0xFF).toString(16) +
      (i & 0xFF).toString(16);
    // Sometimes the string returned will be too short so we 
    // add zeros to pad it out, which later get removed if
    // the length is greater than six.
    hex += '000000';
    return hex.substring(0, 6);
  }

  /**
   * Converte hexadecimal para inteiro
   */
  function hex2int(hex) { return parseInt(hex, 16) }

  /**
   * Converte inteiro para hexadecimal
   */
  function int2hex(int) { return (int).toString(16) }

  /**
   * Converte uma cor em inteiro para uma cor em hexadecimal
   */
  function hexToRGB(hexcolor) {
    return {
      red: hex2int(hexcolor.substr(0, 2)),
      green: hex2int(hexcolor.substr(2, 2)),
      blue: hex2int(hexcolor.substr(4, 2)),
      alpha: alpha || 100
    }
  }

  /**
   * Calcula se uma cor informada é light ou dark
   */
  function lightOrDark(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

      return 'light';
    }
    else {

      return 'dark';
    }
  }


  return {
    /**
     * 
     */
    asHex() {
      return intToARGB(hashCode(str))
    },

    /**
     * 
     */
    asRGB() {
      return hexToRGB(intToARGB(hashCode(str)));
    },

    /**
     * 
     */
    setAlpha(intAlpha) {
      alpha = intAlpha;
      return this;
    },

    /**
     * Retorna true ou false se for uma cor clara
     */
    isLight() {
      const color = intToARGB(hashCode(str))
      return lightOrDark(color) === 'light'
    },

    /**
     * Retorna true ou false se for uma cor escura
     */
    isDark() {
      const color = intToARGB(hashCode(str))
      return lightOrDark(color) === 'dark'
    },
  }
}

module.exports = stringToHex;