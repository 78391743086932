<template>
  <v-btn icon @click="onToggleTheme">
    <v-icon>{{
      $vuetify.theme.dark ? "mdi-brightness-4" : "mdi-brightness-7"
    }}</v-icon>
  </v-btn>
</template>

<script>
import Storage from "@/plugins/Storage";

export default {
  methods: {
    /**
     * Ao trocar o tema da aplicação de claro para escuro
     */
    onToggleTheme() {
      Storage.set("theme:dark", !this.$vuetify.theme.dark);
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
};
</script>

<style>
</style>
