import { render, staticRenderFns } from "./KAvatar.vue?vue&type=template&id=9368febc&scoped=true"
import script from "./KAvatar.vue?vue&type=script&lang=js"
export * from "./KAvatar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9368febc",
  null
  
)

/* custom blocks */
import block0 from "./KAvatar.vue?vue&type=custom&index=0&blockType=k-avatar&size=48&name=Jos%C3%A9%20Cl%C3%A1udio&picture=https%3A%2F%2Fpic.com%2F123"
if (typeof block0 === 'function') block0(component)

export default component.exports