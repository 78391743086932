import Api from "@/plugins/Api";
import Vue from 'vue';


// Controla o token do axios para evitar duas requisições ao mesmo tempo
import axios from 'axios'
let axiosCancelToken = null;

const baseUrl = '/collections'

export default {
  namespaced: true,
  state: {
    items: [],
    pagination: {},
    condominium: {},

  },
  mutations: {
    changeItems(stateObject, payload = {}) {
      const { data, ...pagination } = payload

      stateObject.items = data;
      if (Object.keys(pagination).length)
        stateObject.pagination = pagination;


      // Preenche o dado do condomínio
      if (data && data.length) {
        stateObject.condominium = data[0].condominium
      }
    },
    updateItem(stateObject, user) {
      const index = stateObject.items.findIndex(item => item.id === user.id);
      // console.log('index', index);
      Vue.set(stateObject.items, index, user);
    },

    clearState(stateObject) {
      stateObject.items = []
      stateObject.pagination = {}
    }
  },
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }) {
      if (state.items.length === 0) {

        await dispatch('refresh')
      }
      return state.items;
    },


    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }, query = {}) {
      try {
        // Cancela a requisição anterior se existir
        if (axiosCancelToken) axiosCancelToken.cancel()


        // Adiciona o full à query
        query.full = true

        // Cria um novo token de cancelamento
        axiosCancelToken = axios.CancelToken.source();
        const options = { cancelToken: axiosCancelToken.token }

        let { data: { data } } = await Api.get(baseUrl, query, options);
        commit("changeItems", data);
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error
        }
      }
    },

    /**
     * Atualiza um registro no store.
     * Se o registro existir, altere.
     * Se não existir, adicione.
     * Chama sort para adicionar os dados ordenados
     */
    async save({ commit, state, dispatch }, { payload, editing }) {
      try {

        let items = state.items || [];

        // Editando
        if (editing) {
          // Requisição
          const { data: { data } } = await Api.put(`${baseUrl}/${payload.id}`, payload)
          // Altero somente o usuário modificado com os dados retornados da requisição
          dispatch('updateItemInList', data)
          return data

          // Novo
        } else {
          const { data: { data } } = await Api.post(baseUrl, payload)
          items.push(data)
          commit('update', items)
          return data;
        }

      } catch (error) {
        throw error;
      }
    },

    async cancel({ commit, state, dispatch }, payload = {}) {
      try {
        const { data: { data } } = await Api.patch(`${baseUrl}/${payload.id}/cancel`, payload)
        dispatch('updateItemInList', data)
        return data
      } catch (error) {
        throw error
      }
    },
    async reset({ commit, state, dispatch }, payload = {}) {
      try {
        const { data: { data } } = await Api.patch(`${baseUrl}/${payload.id}/reset`, payload)
        dispatch('updateItemInList', data)
        return data
      } catch (error) {
        throw error
      }
    },





    /**
     * Atualiza os dados de um usuário na lista de usuários
     */
    updateItemInList({ commit, state }, payload) {

      console.log('dados recebidos dentro do store', payload);

      let items = state.items;
      // Pesquisando o item atual no store e alterando somente ele

      items = items.map(item => {
        return item.id === payload.id ? payload : item
      })

      commit('changeItems', { data: items });
    },
  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },
    pagination(state) {
      return state.pagination || {};
    },
    condominium(state) {
      return state.condominium || {};
    },
    statuses() {
      return [
        {
          value: "collected",
          text: "Coletado"
        },
        {
          value: "pendent",
          text: "Pendente"
        },
        {
          value: "ignored",
          text: "Abaixo do mínimo"
        },
        {
          value: "cancelled",
          text: "Cancelado"
        }
      ];
    }
  }
};
