import Api from "@/plugins/Api";
const baseURL = 'faqs';

export default {
  // Define que este será acessado via namespace
  namespaced: true,

  // Armazena o estado
  state: {
    // Armazena o usuário autenticado
    items: []
  },

  // Responsável por alterar o estado diretamente
  mutations: {
    updateData(stateObject, data) {
      // console.log('entrou');

      stateObject.items = data;
    },
    clearState(stateObject) {
      stateObject.items = []
    }
  },

  // Executa upload, consultas a bancos de ddos, apis, etc.
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit('clearState')
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }, params) {
      if (state.items.length === 0) {

        await dispatch('refresh', params)
      }
      return state.items;
    },

    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }, query = {}) {
      // console.log('busca', query);

      let response = await Api.get(`/${baseURL}`, query);
      //console.log('refresh')
      if (response.data.error === false) {
        response = response.data.data
      } else {
        response = []
      }
      commit("updateData", response);
      return response;
    },

    /**
     * Força a atualização dos dados
     */
    async view({ commit }, route) {
      try {

        // console.log('route', route.params.id);
        const { id } = route.params;


        const { data: { data } } = await Api.get(`/${baseURL}/${id}`);


        return data;
      } catch (error) {
        throw error
      }
    },

    /**
     * Força a atualização dos dados
     */
    async rate({ commit }, { id, payload }) {
      try {
        const { data: { data } } = await Api.post(`/${baseURL}/rate/${id}`, payload);
        return data;
      } catch (error) {
        throw error
      }
    },

    /**
      * Atualiza um registro no store.
      * Se o registro existir, altere.
      * Se não existir, adicione.
      * Chama sort para adicionar os dados ordenados
      */
    async save({ commit, state, dispatch }, { payload, editing }) {
      try {
        // console.log(payload);
        let result;

        let items = state.items;
        // Editando
        if (editing) {
          // 
          const { data: { data } } = await Api.put(`/admin/${baseURL}/${payload.id}`, payload);
          // 
          items = items.map(item => {
            return item.id === payload.id ? data : item
          })
          result = data;

          // Novo
        } else {
          const { data: { data } } = await Api.post(`/admin/${baseURL}`, payload);
          items.push(data)
          result = data;
        }

        // dispatch('updateSorted', items)
        commit('updateData', items);

        return result;
      } catch (error) {
        throw error;
      }
    },

    /**
     * Cria um rascunho
     */
    async draft({ commit, state }) {
      try {
        let items = state.items;
        const { data: { data } } = await Api.post(`/admin/${baseURL}/draft`);
        items.push(data)
        // Des
        return { ...data };
      } catch (error) {

        throw error;
      }
    },

    /**
     * Importa uma FAQ do github
     */
    async importFromGithub({ commit, state }, payload) {
      try {
        let items = state.items;
        const { data: { data } } = await Api.post(`/admin/${baseURL}/import`, payload);
        items.push(data)
        // Des
        return { ...data };
      } catch (error) {
        throw error;
      }
    },

    /**
     * Sincroniza um conjunto selecionado de FAQs importadas
     */
    async synchronizeImported({ dispatch, state }) {
      try {
        let items = state.items;
        const { data: { data } } = await Api.post(`/admin/${baseURL}/sync`);

        dispatch('refresh', { show_unpublished: true })

        return data
      } catch (error) {
        console.log(error)
        throw error;
      }
    },

    /**
     * 
     */
    async upload({ commit, state }, { file, ...payload }) {
      try {
        //   console.log('Upload(', payload.id, ')', file, payload);

        const uploadURL = `/admin/${baseURL}/upload/${payload.id}`
        const { data: { data } } = await Api.upload(uploadURL, file.file, {
          fieldName: "image", method: 'post', data: payload
        });
        // console.log('data', data);
        const items = state.items.map(item => {
          if (item.id == data.module_id) {
            item.images.push(data)
          }
          return item;
        })

        commit('updateData', items);
        return data;


      } catch (error) {
        throw error
      }
    },





    /**
     * Atualiza os partidos de forma ordenada pelo nome
     */
    updateSorted({ commit }, items) {
      //console.log('store/chamou sort')
      function compare(a, b) {
        if (a.title.toLowerCase() < b.title.toLowerCase())
          return -1;
        if (a.title.toLowerCase() > b.title.toLowerCase())
          return 1;
        return 0;
      }

      commit('updateData', items.sort(compare));
    },


    /**
     * Remove o cadastro
     */
    async remove({ commit, state }, payload) {
      try {
        // Requisição para deletar
        const { data } = await Api.delete(`/admin/${baseURL}/${payload.id}`);

        // Filtra para retirar do state
        const items = state.items.filter(item => item.id !== payload.id);

        // Dá o commit no state
        commit('updateData', items)

        // Retorna o resultado para a exibição das mensagens na página
        return data;
      } catch (error) {
        //console.log('erro do store', error.response)
        throw error.response;
      }
    },

    /**
     * Remove o cadastro
     */
    async removeImage({ commit, state }, payload) {
      try {
        // console.log('entrou em removeimage', payload);

        // console.log(`/admin/${baseURL}/upload/${payload.module_id}/${payload.id}`);

        // Requisição para deletar
        await Api.delete(`/admin/${baseURL}/upload/${payload.module_id}/${payload.id}`);

        // Filtra para retirar do state
        const items = state.items
          .map(item => {
            // console.log('dentro do map', item.id, payload.module_id);

            if (item.id === payload.module_id) {

              return {
                ...item,
                images: item.images.filter(img => img.id !== payload.id)
              }

            }
            return item;
          })
        // console.log('resultado', items);


        // Dá o commit no state
        commit('updateData', items)

        // Retorna o resultado para a exibição das mensagens na página
        // return data;
      } catch (error) {
        //console.log('erro do store', error.response)
        throw error;
      }
    }

  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      return state.items || [];
    },
  }
};
