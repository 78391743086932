import Vue from 'vue'

import VueRouter from 'vue-router'
import authGuard from './authGuard'
import { scrollBehavior, afterEach } from './utils'
import routes from './routes'



Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

router.beforeEach(authGuard)
router.afterEach(afterEach)

export default router
