export default [

  {
    path: '/collect/condominiums',
    name: 'collect-condominiums',
    component: () => import(/* webpackChunkName: "condominiums" */ '@/views/Collect/condominiums/PageIndex.vue'),
    meta: {
      title: "Condomínios",
      KMenu: { parent: 'collect', show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/collect/condominiums/add',
    name: 'collect-condominiums-add',
    component: () => import(/* webpackChunkName: "condominiums" */ '@/views/Collect/condominiums/PageView.vue'),
    meta: {
      title: "Novo Condomínio",
      backButton: '/collect/condominiums',
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },

  {
    path: '/collect/condominiums/view/:condominium_id',
    name: 'collect-condominiums-view',
    component: () => import(/* webpackChunkName: "condominiums" */ '@/views/Collect/condominiums/PageView.vue'),
    meta: {
      title: "Condomínio",
      backButton: '/collect/condominiums',
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },


  // Solicitações de coleta de um condomínio
  {
    path: '/collect/condominiums/:condominium_id/collections',
    name: 'collect-condominiums-collections',
    component: () => import(/* webpackChunkName: "condominiums" */ '@/views/Collect/condominiums/PageCollections.vue'),
    meta: {
      title: "Coletas",
      backButton: ({ params }) => `/collect/condominiums/view/${params.condominium_id}`,
      KMenu: { parent: 'collect', show: false, },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ['dev', "admin", 'manager', 'operator']
    }
  },
]
