/** 
<k-avatar size="48" name="José Cláudio" picture="https://pic.com/123"  ></k-avatar>
*/
<template>
  <div>
    <v-avatar
      :left="left"
      :tile="tile"
      v-if="getData.type == 'img'"
      :size="size"
    >
      <k-image
        style="margin-top: 4px"
        :src="getData.picture"
        :width="size"
        :height="size"
        :timeout="timeout"
      />
    </v-avatar>

    <v-avatar
      :left="left"
      :tile="tile"
      v-if="getData.type == 'text'"
      :color="getData.color"
      :size="size"
    >
      <span
        :style="{ color: getData.textColor, fontSize: size * 0.6 + 'px' }"
        >{{ getData.text }}</span
      >
    </v-avatar>
  </div>
</template>

<script>
import stringToColor from "@/plugins/stringToColor";
import KImage from "./KImage";

export default {
  name: "k-avatar",
  components: { KImage },
  props: {
    // Originais
    size: { type: [Number, String], default: 48 }, //tamanho, padrao 48
    tile: Boolean, // define se é quadrado
    left: Boolean,

    // Personalizados
    name: String,
    picture: String,
    // Usa um timeout para personalizar o tempo do carregamento do k-image
    timeout: { type: Number, default: 1000 }
  },

  data() {
    return {};
  },

  computed: {
    /**
     * Retorna os dados para geração do avatar, no formato
     * {
     *  type: 'text',     // 'text' || 'img'
     *  text: "J",        // User name's first letter
     *  color: "#5900b2", // background color
     *  textColor: "#FFF", // text color
     *  picture: 'pic.com/123' // image url for 'img' type
     * }
     */
    getData() {
      if (!this.name) {
        return {};
      }

      const color = stringToColor(this.name);

      if (this.picture) {
        return { type: "img", picture: this.picture };
      } else {
        return {
          type: "text",
          text: this.getInitials,
          color: "#" + color.asHex(), //"#5900b2"
          textColor: color.isLight() ? "#000" : "#FFF"
        };
      }
    },

    /**
     * Retorna a inicial do nome
     */
    getInitials() {
      return (this.name && this.name.charAt(0).toUpperCase()) || "";
    }
  },

  methods: {}
};
</script>

<style scoped>
</style>