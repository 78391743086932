export default [
  {
    path: "/financials",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials_dashboard" */ "@/views/Finance/dashboard/PageDashboard.vue"
      ),
    meta: {
      title: "Financeiro",
      KMenu: { icon: "mdi-finance" },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev", "admin", "manager", "operator"],
    },
  },

  {
    path: "/financials/search",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials_search" */ "@/views/Finance/search/PageSearch.vue"
      ),
    meta: {
      title: "Financeiro",
      KMenu: { icon: "mdi-finance", show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev", "admin", "manager", "operator"],
    },
  },

  // /////////////////////////
  //
  // RELATÓRIOS
  //
  // /////////////////////////

  {
    path: "/financials/reports/debtors",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials" */ "@/views/Finance/reports/PageDebtors.vue"
      ),
    meta: {
      title: "Devedores",
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev", "admin", "manager", "operator"],
    },
  },

  {
    path: "/financials/reports/balance",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials_report" */ "@/views/Finance/reports/PageBalance.vue"
      ),
    meta: {
      title: "Balancete Mensal",
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev", "admin", "manager", "operator"],
    },
  },

  {
    path: "/financials/reports/category",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials_report" */ "@/views/Finance/reports/PageCategoryBalance.vue"
      ),
    meta: {
      title: "Balancete por Categoria",
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev", "admin", "manager", "operator"],
    },
  },

  // /////////////////////////
  //
  // TABELAS AUXILIARES
  //
  // /////////////////////////

  {
    path: "/financials/gateways",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials_auxiliary" */ "@/views/Finance/gateways/PageIndex.vue"
      ),
    meta: {
      title: "Gateways de Pagamento",
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },

  {
    path: "/financials/connections",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials_auxiliary" */ "@/views/Finance/connections/PageIndex.vue"
      ),
    meta: {
      title: "Conexões aos Gateways",
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev", "admin"],
    },
  },

  {
    path: "/financials/statuses",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials_auxiliary" */ "@/views/Finance/statuses/PageIndex.vue"
      ),
    meta: {
      title: "Status",
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },

  {
    path: "/financials/categories",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials_auxiliary" */ "@/views/Finance/categories/PageIndex.vue"
      ),
    meta: {
      title: "Categorias",
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev", "admin", "manager", "operator"],
    },
  },

  {
    path: "/financials/methods",
    name: "financials",
    component: () =>
      import(
        /* webpackChunkName: "financials_auxiliary" */ "@/views/Finance/methods/PageIndex.vue"
      ),
    meta: {
      title: "Métodos de Pagamento",
      KMenu: { show: false },
      // Define quais os níveis de usuário que podem acessar o componente
      roles: ["dev"],
    },
  },
];
