"use strict";

import Vue from "vue";

import moment from 'moment'
moment.locale('pt-BR')

import { applyMask, empty } from 'klawtil'
import { filesize, countString } from '../plugins/utils'

/**
 * Devolve o valor como string de tempo passado.
 * Exemplo: Há 2 semanas, Há 1 minuto etc
 * @param {*} value 
 */
Vue.filter('timeAgo', (value) => {
  try {
    if (!value) {
      return "";
    }

    return moment(value).fromNow()
  } catch (error) {
    throw error
  }
})
/**
 * Devolve o valor como string de tempo passado.
 * Exemplo: Há 2 semanas, Há 1 minuto etc
 * @param {*} value 
 */
Vue.filter('timeAgoFull', (value) => {
  try {
    if (!value) {
      return "";
    }

    return moment(value).calendar()
  } catch (error) {
    throw error
  }
})

/**
 * Devolve o valor como string de tempo passado.
 * Exemplo: Há 2 semanas, Há 1 minuto etc
 * @param {*} value 
 */
Vue.filter('dateBR', (value) => {
  try {
    if (!value) {
      return "";
    }

    return moment(value).format('DD/MM/YYYY')
  } catch (error) {
    throw error
  }
})

/**
 * Devolve o valor como string de tempo passado.
 * Exemplo: Há 2 semanas, Há 1 minuto etc
 * @param {*} value 
 */
Vue.filter('dateFullBR', (value) => {
  try {
    if (!value) {
      return "";
    }

    return moment(value).format('DD/MM/YYYY HH:mm:ss')
  } catch (error) {
    throw error
  }
})

/**
 * Devolve o valor como moeda em reais
 * @param {*} value 
 */
Vue.filter('currency', (value) => {
  try {
    if (!value) {
      return "";
    }

    return Number(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  } catch (error) {
    throw error
  }
})

/**
 * Devolve o valor formatado com n casas decimais
 * @param {*} value 
 */
Vue.filter('precision', (value, precision = 2) => {
  try {
    if (!value && value !== 0) {
      return "";
    }
    return Number(value).toFixed(precision)

  } catch (error) {
    throw error
  }
})

/**
 * Transforma a primeira letra do texto para maiúscula
 */
Vue.filter("capitalize", function (value) {
  try {
    if (!value) {
      return "";
    }
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  } catch (error) {
    throw error
  }
});

/**
 * Transforma o texto para maiúsculas
 */
Vue.filter("uppercase", function (value) {
  try {
    if (!value) {
      return "";
    }

    value = value.toString();

    return value.toUpperCase()
  } catch (error) {
    throw error
  }

});
/**
 * Transforma as iniciais do texto para maiúsculas
 */
Vue.filter("upperFirst", function (value) {
  try {
    if (!value) {
      return "";
    }

    value = value.toString().toLowerCase();

    const ignore = ['e', 'de', 'da', 'do', 'das', 'dos']

    const parts = value.split(' ').map(part => {
      if (ignore.includes(part)) {
        return part
      } else {
        return part.charAt(0).toUpperCase() + part.slice(1)
      }
    })

    return parts.join(' ');
  } catch (error) {
    throw error
  }

});

/**
 * Transforma o texto para minúsculas
 */
Vue.filter("lowercase", function (value) {
  try {
    if (!value) {
      return "";
    }

    value = value.toString();
    return value.toLowerCase()
  } catch (error) {
    throw error
  }

});

/**
 * Trunca um texto em um número definido de caracteres
 */
Vue.filter("truncate", function (value, length, suffix = '...') {
  try {
    if (!value) {
      return "";
    }

    if (value.length > length) {
      return value.substring(0, length) + suffix;
    } else {
      return value;
    }
  } catch (error) {
    throw error
  }

});

/**
 * Trunca um texto em um número definido de caracteres
 */
Vue.filter("mask", function (value, mask = null) {
  try {
    if (!value) return "";
    return applyMask(value, mask)
  } catch (error) {
    throw error
  }

});

/**
 * Exibe um valor em bytes em um múltiplo correspondente.
 * 
 * @example
 * {{ 1024 | filesize }} // -> 1kb
 * 
 */
Vue.filter("filesize", function (value) {
  try {
    if (!value) return "";
    return filesize(value)
  } catch (error) {
    throw error
  }

});

/**
 * Exibe uma string com seu valor no plural ou singular
 * dependendo da quantidade
 * 
 * 
 * @example
 * 
 * {{ 0 | quantity( "dados", "dado" ) }}
 * // -> exibe "2 dados", "1 dado" ou "0 dado" dependendo da quantidad
 * 
 * {{ 0 | quantity( "dados", "dado", "nada" ) }}
 * // -> exibe "2 dados", "1 dado" ou "nada" dependendo da quantidade.
 * 
 * @param 
 * 
 * 
 */
Vue.filter("quantity", function (value, suffixMany, suffixOne, suffixNone = null) {
  try {

    if (empty(value)) return "";
    return countString(value, suffixMany, suffixOne, suffixNone)
  } catch (error) {
    throw error
  }

});
