import Api from "@/plugins/Api";
import Vue from "vue";

// Controla o token do axios para evitar duas requisições ao mesmo tempo
import axios from "axios";
let axiosCancelToken = null;

const baseUrl = "/financials/categories";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    changeItems(stateObject, payload = {}) {
      stateObject.items = payload;
    },
    updateItem(stateObject, user) {
      const index = stateObject.items.findIndex(item => item.id === user.id);
      Vue.set(stateObject.items, index, user);
    },
    removeItem(stateObject, payload) {
      const index = stateObject.items.findIndex(item => item.id === payload.id);
      Vue.delete(stateObject.items, index);
    },

    clearState(stateObject) {
      stateObject.items = [];
      stateObject.pagination = {};
    },
  },
  actions: {
    /**
     * Limpa o estado do módulo
     */
    clear({ commit }) {
      commit("clearState");
    },

    /**
     * Devolve os dados do store. Se não tiver, tenta atualizar
     */
    async all({ commit, state, dispatch }) {
      if (state.items.length === 0) {
        await dispatch("refresh");
      }
      return state.items;
    },

    /**
     * Força a atualização dos dados
     */
    async refresh({ commit }, query = {}) {
      try {
        // Cancela a requisição anterior se existir
        if (axiosCancelToken) axiosCancelToken.cancel();

        query.paginate = false;

        // Cria um novo token de cancelamento
        axiosCancelToken = axios.CancelToken.source();
        const options = { cancelToken: axiosCancelToken.token };

        let {
          data: { data },
        } = await Api.get(baseUrl, query, options);

        commit("changeItems", data);
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error;
        }
      }
    },

    /**
     * Atualiza um registro no store.
     * Se o registro existir, altere.
     * Se não existir, adicione.
     * Chama sort para adicionar os dados ordenados
     */
    async save({ commit, state, dispatch }, { payload, editing }) {
      try {
        let items = state.items || [];

        // Editando
        if (editing) {
          // Requisição
          const {
            data: { data },
          } = await Api.put(`${baseUrl}/${payload.id}`, payload);
          // Altero somente o usuário modificado com os dados retornados da requisição
          dispatch("updateItemInList", data);
          return data;

          // Novo
        } else {
          const {
            data: { data },
          } = await Api.post(baseUrl, payload);
          items.push(data);
          commit("update", items);
          return data;
        }
      } catch (error) {
        throw error;
      }
    },

    async remove({ commit, state, dispatch }, payload = {}) {
      try {
        const {
          data: { data },
        } = await Api.delete(`${baseUrl}/${payload.id}`, payload);
        commit("removeItem", data);
        return data;
      } catch (error) {
        throw error;
      }
    },

    /**
     * Atualiza os dados de um usuário na lista de usuários
     */
    updateItemInList({ commit, state }, payload) {
      let items = state.items;
      // Pesquisando o item atual no store e alterando somente ele

      items = items.map(item => {
        return item.id === payload.id ? payload : item;
      });

      commit("changeItems", items);
    },
  },

  // Retorna valores existentes no estado
  getters: {
    all(state) {
      if (!state.items || !Array.isArray(state.items)) return [];
      return state.items.map(item => {
        if (item.category) item.full = `${item.category.name} > ${item.name}`;
        else item.full = item.name;

        return item;
      });
    },
  },
};
