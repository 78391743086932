/*
 * Validação como mixins
 *
 * ---------------------------
 * OBSERVAÇÕES
 * ---------------------------
 * Dentro dos mixins, 'this' é relativo ao contexto do componente
 */
import Utils from "./utils";
import PersonalIDs from "./personalIDs";
import PostalCodes from "./postalCodes";

const Validation = {
  /**
   * Objeto de mensagens de erro
   */
  messages: {
    //Formatos
    cpf: "CPF inválido",
    cnpj: "CNPJ inválido",
    time: "Hora inválida",
    date: "Data inválida",
    email: "E-mail inválido",
    url: 'URL inválida',
    postalCode: "Número postal inválido",
    regex: 'Formato inválido',
    social_username: 'Valor inválido',
    latitude: 'Não é uma latitude válida',
    longitude: 'Não é uma longitude válida',
    //Comparações
    unique: "Já existe",
    equalTo: "Os campos não coincidem",
    numeric: "Não é um número válido",
    integer: "Não é um número inteiro válido",
    required: "Campo obrigatório",
    length: "Campo com quantidade de caracteres diferente do permitido",
    maxLength: "Campo com mais caracteres que o permitido",
    minLength: "Campo com menos caracteres que o permitido",
    smallerThan: "Valor maior que o permitido",
    biggerThan: "Valor menor que o permitido",
    //
  },

  /**
   *
   */
  _errorFields: [],

  /**
   * Verifica se o campo está preenchido
   */
  isRequired(value, msg) {
    return Utils.empty(value)
      ? Validation._handleMessages("required", msg)
      : Validation._setValid("required");
  },

  /**
   * Verifica se 'value' está contido no array 'arrayToCompare'
   */
  isUniqueInArray(value, arrayToCompare, msg) {
    return Utils.isArray(arrayToCompare) && arrayToCompare.indexOf(value) === -1
      ? true
      : Validation._handleMessages("unique", msg);
  },

  /**
   * Verifica se o valor casa com uma expressão regular
   */
  regex(value, regex, msg) {
    // return new RegExp(regex).test(value)
    return Utils.empty(value) || new RegExp(regex).test(value)
      ? true
      : Validation._handleMessages("regex", msg);

  },

  /**
   * Verifica se o valor casa com uma expressão regular
   */
  isSocialUsername(value, msg) {

    // return new RegExp(regex).test(value)
    return Utils.empty(value) || /^[a-zA-Z0-9_\.-]+$/.test(value)
      ? true
      : Validation._handleMessages("social_username", msg);

  },

  /**
   * Verifica se é um CPF válido
   * Só valida se o campo não estiver vazio
   */
  isCPF(value, msg) {
    return Utils.empty(value) || PersonalIDs.isValidCPF(value)
      ? true
      : Validation._handleMessages("cpf", msg);
  },

  /**
   * Verifica se é um cnpj
   */
  isCNPJ(value, msg) {
    return Utils.empty(value) || PersonalIDs.isValidCNPJ(value)
      ? true
      : Validation._handleMessages("cnpj", msg);
  },

  /**
   * Verifica se é um cnpj
   */
  isPostalCode(value, msg) {
    return Utils.empty(value) || PostalCodes.isValid(value)
      ? true
      : Validation._handleMessages("postalCode", msg);
  },

  /**
   * Verifica se é um e-mail válido.
   * Só valida se o campo não estiver vazio
   */
  isEmail(value, msg) {
    if (Utils.empty(value) || Utils.isEmail(value)) {
      return true;
    }

    return Validation._handleMessages("email", msg);
  },

  /**
   * Verifica se é uma url válida.
   * Só valida se o campo não estiver vazio
   */
  isUrl(value, msg) {
    if (Utils.empty(value) || Utils.isUrl(value)) {
      return true;
    }

    return Validation._handleMessages("url", msg);
  },

  /**
   * Verifica se 2 campos possuem o mesmo valor
   */
  isEqualTo(field1, field2, msg) {
    //if( Utils.isValidCPF( value ) ){
    //return true;
    //}

    return Validation._handleMessages("equalTo", msg);
  },

  /**
   * Verifica se é um valor numérico
   */
  isNumeric(value, msg) {
    return Utils.empty(value) || Utils.isNumeric(value) || Utils.isFloat(value)
      ? true
      : Validation._handleMessages("numeric", msg);
  },

  /**
   * Verifica se é um valor inteiro
   */
  isInteger(value, msg) {
    return Utils.empty(value) || Utils.isInteger(value)
      ? true
      : Validation._handleMessages("integer", msg);
  },

  /**
   * Verifica se é uma data no padrão do javascript
   */
  isDate(value, msg) {
    return Utils.empty(value) || Utils.isDate(value)
      ? true
      : Validation._handleMessages("date", msg);
  },

  /**
   * Só valida se não estiver vazio
   */
  isTime(value, msg) {
    return Utils.empty(value) || Utils.isTime(value)
      ? true
      : Validation._handleMessages("time", msg);
  },

  /**
   * Vrifica se é uma latitude
   */
  isLatitude(value, msg) {
    return Utils.empty(value) || Utils.isLatitude(value)
      ? true
      : Validation._handleMessages("latitude", msg);
  },

  /**
   * Só valida se não estiver vazio
   */
  isLongitude(value, msg) {
    return Utils.empty(value) || Utils.isLongitude(value)
      ? true
      : Validation._handleMessages("longitude", msg);
  },

  /**
   * Verifica se o número de caracteres é exatamente o definido
   */
  hasLength(value, length, msg) {
    if (String(value).length === parseInt(length)) {
      return true;
    }

    return Validation._handleMessages("length", msg);
  },

  /**
   * Verifica se o número de caracteres é no mínimo o definido
   */
  hasMinLength(value, minLength, msg) {
    if (String(value).length >= parseInt(minLength)) {
      return true;
    }

    return Validation._handleMessages("minLength", msg);
  },

  /**
   * Verifica se o número de caracteres é no máximo o definido
   */
  hasMaxLength(value, maxLength, msg) {
    if (String(value).length <= parseInt(maxLength)) {
      return true;
    }

    return Validation._handleMessages("maxLength", msg);
  },
  /**
   * Verifica se o valor é menor que o definido
   */
  isSmallerThan(value, maxValue, msg) {
    if (value < maxValue) {
      return true;
    }

    return Validation._handleMessages("smallerThan", msg);
  },

  /**
   * Verifica se o valor é maior que o definido
   */
  isBiggerThan(value, minValue, msg) {
    if (value > minValue) {
      return true;
    }

    return Validation._handleMessages("biggerThan", msg);
  },

  /*****************
   * PRIVADOS
   *
   */

  /**
   * @todo Armazenará os campos sem erros para ajudar na validação
   */
  _setValid(messageKey) {
    return true;
  },

  /**
   * Manipula as mensagens de erro, substituindo-as por mensagens personalizadas caso estas
   * sejam definidas
   *
   * @param {String} messageKey
   * @param {String} messageText
   */
  _handleMessages(messageKey, messageText) {
    return (
      messageText ||
      Validation.messages[messageKey] ||
      'Mensagem não cadastrada para "' + messageKey + '()"'
    );
  }
};

export default {
  //Formatos
  isCPF: Validation.isCPF,
  isCNPJ: Validation.isCNPJ,
  isPostalCode: Validation.isPostalCode,
  isTime: Validation.isTime,
  isDate: Validation.isDate,
  isEmail: Validation.isEmail,
  isUrl: Validation.isUrl,
  isLatitude: Validation.isLatitude,
  isLongitude: Validation.isLongitude,
  regex: Validation.regex,
  isSocialUsername: Validation.isSocialUsername,
  //Comparações
  isEqualTo: Validation.isEqualTo,
  isNumeric: Validation.isNumeric,
  isInteger: Validation.isInteger,
  isRequired: Validation.isRequired,
  isUniqueInArray: Validation.isUniqueInArray,
  hasLength: Validation.hasLength,
  hasMinLength: Validation.hasMinLength,
  hasMaxLength: Validation.hasMaxLength,
  isSmallerThan: Validation.isSmallerThan,
  isBiggerThan: Validation.isBiggerThan,
};
